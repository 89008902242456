import React from 'react';
import PropTypes from 'prop-types';

const BrandImage = ({ brandImage, isModal }) => {
  return (
    <div
      className={`mr-2 p-6 rounded-full flex bg-white justify-center items-center shadow-md ${isModal ? 'w-[180px] h-[180px]' : ''}`}
    >
      <img
        src={brandImage}
        alt="brand"
        className={isModal ? 'w-24 h24' : 'w-20 h-20'}
      />
    </div>
  );
};

BrandImage.propTypes = {
  brandImage: PropTypes.string.isRequired,
};

export default BrandImage;
