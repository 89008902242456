import React from 'react';
import PropTypes from 'prop-types';

import { Listbox } from '@headlessui/react';
import { useState } from 'react';

const SelectListbox = ({ data, action }) => {
  const [selected, setSelected] = useState(data[0]);

  const handleAction = (value) => {
    setSelected(value);
    action(value);
  };

  return (
    <Listbox value={selected} onChange={handleAction}>
      <Listbox.Button className="flex px-6 py-4 justify-start w-full text-gray-700 bg-gray-100 rounded-md">
        {selected}
      </Listbox.Button>
      <Listbox.Options>
        {data.map((item) => (
          <Listbox.Option
            key={`${item} - ${Math.random()}`}
            value={item}
            className={({ active }) =>
              `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
              }`
            }
          >
            {item}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

SelectListbox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.func,
};

export default SelectListbox;
