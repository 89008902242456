import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';

const people = [
  { id: 1, name: 'José Queiroz' },
  { id: 2, name: 'José Simão' },
  { id: 3, name: 'João Macedo' },
  { id: 4, name: 'Cristiano Ronaldo' },
  { id: 5, name: 'Lionel Messi' },
];

const AutoCompleteCombobox = () => {
  const [selected, setSelected] = useState(people[0]);
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) =>
          person.name.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <div className="relative w-full">
      <Combobox value={selected} onChange={setSelected}>
        <Combobox.Input
          className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-01 focus:border-yellow-01"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person.name}
        />
        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
          {filteredPeople.map((person, idx) => (
            <Combobox.Option
              key={idx}
              value={person}
              className={({ active }) =>
                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                }`
              }
            >
              {person.name}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};

export default AutoCompleteCombobox;
