import React, { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  Squares2X2Icon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';

const sortOptions = [
  { name: 'Newest to Oldest', value: 'newest' },
  { name: 'Oldest to Newest', value: 'oldest' },
  { name: 'Price: High to Low', value: 'high-to-low' },
  { name: 'Price: Low to High', value: 'low-to-high' },
];

const SortBar = ({ displayType, setDisplayType }) => {
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  return (
    <div className="flex justify-between items-center w-full py-2 bg-gray-100">
      <div className="text-gray-400 text-sm space-x-4">
        <button
          type="button"
          className={`p-1 rounded ${displayType === 'squared' ? 'bg-gray-300 text-gray-600' : 'text-gray-400 hover:text-gray-600'}`}
          onClick={() => setDisplayType('squared')}
        >
          <Squares2X2Icon className="h-6 w-6" />
        </button>
        <button
          type="button"
          className={`p-1 rounded ${displayType === 'list' ? 'bg-gray-300 text-gray-600' : 'text-gray-400 hover:text-gray-600'}`}
          onClick={() => setDisplayType('list')}
        >
          <ListBulletIcon className="h-6 w-6" />
        </button>
      </div>
      <Menu as="div" className="relative">
        <MenuButton className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 text-sm font-medium outline-none text-gray-400">
          sort by: {selectedSort.name}
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </MenuButton>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {sortOptions.map((option) => (
                <MenuItem key={option.value}>
                  {({ active }) => (
                    <button
                      onClick={() => setSelectedSort(option)}
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                    >
                      {option.name}
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
};

export default SortBar;
