import React from 'react';
import PropTypes from 'prop-types';

const SpaceCard = ({ space, active }) => {
  return (
    <div className="flex justify-center flex-col bg-white rounded-3xl p-4 my-4">
      <div className="w-[164px] flex flex-col ">
        <h1 className="text-xl font-bold">{space.name}</h1>
        <h2 className="text-gray-500">{space.size}</h2>
        <p
          className={`w-full h-14 text-xs text-gray-400 ${!active ? 'overflow-hidden' : ''}`}
        >
          {space.description}
        </p>
        <div className="flex-grow" />
        <div className="flex flex-col items-end text-yellow-500 pt-4">
          <span className="text-2xl font-bold">{space.price}€</span>
          <span className="text-gray-400 text-xs">+ Taxes</span>
        </div>
      </div>
    </div>
  );
};

SpaceCard.propTypes = {
  space: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default SpaceCard;
