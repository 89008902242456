import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { categories as allCategories } from 'utils/categories';
import CategoryIcon from '../CategoryIcon';

const CategoryAccordion = ({ categories }) => {
  console.log('🚀🚀 ~  CategoryAccordion ~  categories:', categories);
  const [activeCategories, setActiveCategories] = useState([]);

  const toggleCategory = (macroId) => {
    if (activeCategories.includes(macroId)) {
      setActiveCategories(
        activeCategories.filter((category) => category !== macroId)
      );
    } else {
      setActiveCategories([...activeCategories, macroId]);
    }
  };

  return (
    <div className="p-4 pl-10 rounded-2xl shadow overflow-y-auto">
      <h3 className="text-lg font-bold mb-2">Brand Categories</h3>
      {categories.map((category, idx) => {
        const macroCategory = allCategories.find(
          (cat) => cat.id === category.macroCategory
        );

        if (!macroCategory) {
          console.error(
            `Macro category with ID ${category.macroCategory} not found.`
          );
          return null;
        }

        return (
          <div key={`${macroCategory.id + idx}`}>
            <div
              className="flex items-center justify-between cursor-pointer p-2"
              onClick={() => toggleCategory(macroCategory.id)}
            >
              <div className="flex items-center">
                <CategoryIcon
                  macro={macroCategory.id}
                  className="w-8 h-8 mr-2"
                />
                <span>{macroCategory.macro}</span>
              </div>
              {activeCategories.includes(macroCategory.id) ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </div>
            {activeCategories.includes(macroCategory.id) && (
              <div className="pl-7">
                {category.microCategory.map((microId, idx) => {
                  const microCategory = macroCategory.micro.find(
                    (micro) => micro.id === microId
                  );

                  if (!microCategory) {
                    console.error(
                      `Micro category with ID ${microId} not found in macro category ${macroCategory.macro}.`
                    );
                    return null;
                  }

                  return (
                    <div key={idx} className="flex items-center py-1">
                      <CategoryIcon
                        macro={macroCategory.id}
                        microCat={microCategory.id}
                        className="w-8 h-8 mr-2"
                      />
                      {microCategory.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CategoryAccordion;
