import React from 'react';

const PaymentDetails = () => {
  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      <div className="flex flex-col p-10 justify-between">
        <div className="flex justify-between items-center">
          <h3 className="pt-2 text-sm font-bold text-yellow-02">
            Payment Method
          </h3>
        </div>
        <div>
          <h3 className="pt-2 text-sm font-bold text-yellow-02">
            Payment History
          </h3>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
