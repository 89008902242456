import axios from '../../utils/axiosConfig';

// Action Types
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAIL = 'MARK_NOTIFICATION_READ_FAIL';

export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL';

export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS =
  'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAIL =
  'MARK_ALL_NOTIFICATIONS_READ_FAIL';

export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST =
  'GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST';
export const GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS =
  'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_COUNT_FAIL =
  'GET_UNREAD_NOTIFICATIONS_COUNT_FAIL';

// Action Creators
export const getNotifications = () => async (dispatch, getState) => {
  dispatch({ type: GET_NOTIFICATIONS_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  console.log('🚀🚀 ~  getNotifications ~  token:', token);

  try {
    const res = await axios.get('/notifications', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch notifications',
    });
  }
};

export const markNotificationAsRead =
  (notificationId) => async (dispatch, getState) => {
    const { auth } = getState();
    const token = auth.token;
    try {
      const res = await axios.put(
        `/notifications/${notificationId}/read`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: MARK_NOTIFICATION_READ_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MARK_NOTIFICATION_READ_FAIL,
        payload:
          err.response?.data?.message || 'Failed to mark notification as read',
      });
    }
  };

export const markAllNotificationsAsRead = () => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    await axios.put(
      '/notifications/read-all',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: MARK_ALL_NOTIFICATIONS_READ_FAIL,
      payload:
        err.response?.data?.message ||
        'Failed to mark all notifications as read',
    });
  }
};

export const deleteNotification =
  (notificationId) => async (dispatch, getState) => {
    const { auth } = getState();
    const token = auth.token;
    try {
      await axios.delete(`/notifications/${notificationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: DELETE_NOTIFICATION_SUCCESS,
        payload: notificationId,
      });
    } catch (err) {
      dispatch({
        type: DELETE_NOTIFICATION_FAIL,
        payload: err.response?.data?.message || 'Failed to delete notification',
      });
    }
  };

export const getUnreadNotificationsCount = () => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  dispatch({ type: GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST });
  try {
    const res = await axios.get('/notifications/unread-count', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
      payload: res.data.unreadCount,
    });
  } catch (err) {
    dispatch({
      type: GET_UNREAD_NOTIFICATIONS_COUNT_FAIL,
      payload:
        err.response?.data?.message || 'Failed to fetch unread notifications',
    });
  }
};
