import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Rating from 'components/Rating';
import CategoryIcon from 'components/CategoryIcon';

const BrandCardList = ({ brand, hasNavigation }) => {
  if (!brand) {
    return null;
  }

  const brandDetailUrl = `/brand/${brand.id}`;

  const BrandListCard = () => {
    return (
      <div className="flex flex-row rounded-lg items-center shadow-md bg-white w-full p-4">
        <div className="w-1/12">
          <img className="" src={brand.image} alt={brand.name} />
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col w-1/6 pl-4">
            <h3 className="font-bold text-lg">{brand.name}</h3>
            <p className="text-gray-400 text-sm">{brand.location}</p>
            <Rating rating={brand.rating} />
          </div>
          <div className="flex items-center w-1/6">
            <div className="flex h-full items-center">
              <CategoryIcon macro="fashion_apparel" className="w-6 h-6" />
              <CategoryIcon
                macro="fashion_apparel"
                micro="fa_footwear"
                className="w-6 h-6"
              />
            </div>
          </div>
          <div className="flex items-center w-1/6">
            <p className="text-gray-400 text-sm">
              Confirmed at {brand.eventsCount} events
            </p>
          </div>
          <div className="flex items-center w-1/2 overflow-hidden">
            <p className="text-gray-400 text-sm pr-6">
              {brand.descriptions[0]}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return hasNavigation ? (
    <Link to={brandDetailUrl} className="no-underline text-current">
      {BrandListCard()}
    </Link>
  ) : (
    BrandListCard()
  );
};

BrandCardList.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    location: PropTypes.string,
    rating: PropTypes.number,
    eventsCount: PropTypes.number,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    images: PropTypes.arrayOf(PropTypes.string),
  }),
  hasNavigation: PropTypes.bool,
};

export default BrandCardList;
