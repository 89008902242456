import React from 'react';
import PropTypes from 'prop-types';
import { categories } from 'utils/categories';

const CategoryIcon = ({ macro, microCat, className, ...props }) => {
  const macroCategory = categories.find((category) => category.id === macro);
  if (!macroCategory) {
    return null;
  }

  const microCategory = microCat
    ? macroCategory.micro.find((subCategory) => subCategory.id === microCat)
    : null;

  const macroIcon = macroCategory.icon;
  const microIcon = microCategory?.icon;

  const svgPath = microCat
    ? require(`../../assets/icons/categories/${macroIcon}/${microIcon}.svg`)
    : require(`../../assets/icons/categories/${macroIcon}/${macroIcon}.svg`);

  return (
    <img
      src={svgPath}
      className={`icon ${className}`}
      alt={`${macroCategory.macro} ${microCategory?.name || ''} icon`}
      {...props}
    />
  );
};

CategoryIcon.propTypes = {
  macro: PropTypes.number.isRequired,
  microCat: PropTypes.number,
  className: PropTypes.string,
};

export default CategoryIcon;
