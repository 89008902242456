import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, stickyHeader = false, noFooter }) => {
  return (
    <>
      <Header sticky={stickyHeader} />
      <main>{children}</main>
      {!noFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  stickyHeader: PropTypes.bool,
};

export default Layout;
