import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const SecurityPrivacy = () => {
  const [permissions, setPermissions] = useState([
    {
      name: 'Be searchable by event promoters',
      status: true,
    },
    {
      name: 'Be searchable by guests',
      status: true,
    },
    {
      name: 'Be searchable by brands',
      status: true,
    },
    {
      name: 'Be searchable by influencers',
      status: false,
    },
    {
      name: 'Appear on confirmed events',
      status: false,
    },
    {
      name: 'Show my event history',
      status: false,
    },
  ]);

  const togglePermissionStatus = (index) => {
    const newPermissions = permissions.map((permission, idx) => {
      if (idx === index) {
        return { ...permission, status: !permission.status };
      }
      return permission;
    });
    setPermissions(newPermissions);
  };

  const renderItem = (item) => (
    <div className="flex flex-row items-center">
      <div
        className="flex w-4 h-4 border border-yellow-02 justify-center items-center"
        onClick={() => togglePermissionStatus(permissions.indexOf(item))}
      >
        {item.status && <CheckIcon className="w-4 h-4 text-yellow-02" />}
      </div>
      <p className="ml-2">{item.name}</p>
    </div>
  );

  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      <div className="py-10 px-20">
        <div>
          <h3 className="pt-2 text-sm font-bold text-yellow-02">Password</h3>
          <p>••••••••••••••</p>
        </div>
        <div className="mt-4">
          <div>
            <h3 className="py-2 text-sm font-bold text-yellow-02">
              Permissions
            </h3>
          </div>
          {permissions.map((permission, index) => (
            <div
              key={index}
              className="flex flex-row justify-between items-center pb-2"
            >
              {renderItem(permission)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecurityPrivacy;
