import React from 'react';

const Analytics = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full bg-white p-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      {' '}
      {/* Added h-full to make the component take the full height of the parent */}
      <h2 className="text-lg font-bold">Analytics</h2>
      <p>Work in Progress</p>
      {/* Messages items will be rendered here */}
    </div>
  );
};

export default Analytics;
