import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Rating from 'components/Rating';
import CategoryIcon from 'components/CategoryIcon';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import image1 from '../../assets/images/olkin_1.webp';
import image2 from '../../assets/images/olkin_2.jpeg';
import image3 from '../../assets/images/olkin_3.webp';

const BrandCardWrapper = ({ brand, hasNavigation }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  if (!brand) {
    return null;
  }

  // mock images
  const images = [image1, image2, image3];

  const handleNavigation = (e) => {
    e.stopPropagation();
    if (
      e.target.tagName !== 'BUTTON' &&
      e.target.tagName !== 'svg' &&
      e.target.tagName !== 'path'
    ) {
      navigate(`/brands/${brand.id}`);
    }
  };

  const handlePrevClick = (event) => {
    event.stopPropagation();
    if (index === 0) {
      return null;
    } else {
      setIndex(index - 1);
    }
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    if (index > 0) {
      return null;
    } else {
      setIndex(index + 1);
    }
  };

  const BrandCard = () => {
    return (
      <div className="flex flex-col rounded-3xl shadow-md pb-4 max-w-sm mx-auto scale-90 sm:scale-95 md:scale-100 bg-white">
        <div className="flex">
          <div className="flex-none w-1/3 p-4">
            <img
              className="object-contain h-full"
              src={brand.image}
              alt={brand.name}
            />
          </div>
          <div className="w-2/3 p-4 flex flex-col justify-between">
            <div>
              <h3 className="font-bold text-lg">{brand.name}</h3>
              <p className="text-gray-400 text-sm">{brand.location}</p>
              <Rating rating={brand.rating} />
              <div className="mt-2 flex flex-row space-x-1">
                <CategoryIcon macro="fashion_apparel" className="w-8 h-8" />
                <CategoryIcon
                  macro="fashion_apparel"
                  micro="fa_footwear"
                  className="w-8 h-8"
                />
              </div>
              <div className="mt-2 text-sm italic">
                Confirmed at {brand.eventsCount} events
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row h-20 items-center">
          <button
            className="flex text-gray-800 p-2 items-center z-50"
            onClick={handlePrevClick}
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          {index === 0 ? (
            <div className="text-gray-500 text-xs">{brand.descriptions}</div>
          ) : (
            <div className="flex w-full text-gray-800 items-center z-50">
              {images.map((image, index) => (
                <img
                  src={image}
                  alt="Brand Image"
                  className="h-20 w-20 rounded-lg mr-1"
                />
              ))}
            </div>
          )}
          <button
            className="flex text-gray-800 p-2 items-center z-50"
            onClick={handleNextClick}
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  };

  return hasNavigation ? (
    <div onClick={(e) => handleNavigation(e)}>{BrandCard()}</div>
  ) : (
    BrandCard()
  );
};

BrandCardWrapper.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    location: PropTypes.string,
    rating: PropTypes.number,
    eventsCount: PropTypes.number,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    images: PropTypes.arrayOf(PropTypes.string),
  }),
  hasNavigation: PropTypes.bool,
};

export default BrandCardWrapper;
