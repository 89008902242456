import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';

const DropdownMenu = ({ size }) => {
  const getSizeStyle = () => {
    if (size === 'small') {
      return {
        button: 'w-20 px-2 py-1 text-xs',
        items: 'w-20',
        item: 'px-2 py-1 text-xs',
      };
    }
    if (size === 'large') {
      return {
        button: 'w-60 px-6 py-3 text-md',
        items: 'w-60',
        item: 'px-3 py-2 text-md',
      };
    } else {
      return {
        button: 'w-40 px-4 py-2 text-sm',
        items: 'w-40',
        item: 'px-2 py-2 text-sm',
      };
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={`inline-flex justify-center ${getSizeStyle(size).button} font-medium text-white bg-yellow-500 rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        Options
      </Menu.Button>
      <Menu.Items
        className={`absolute left-0 ${getSizeStyle(size).items} mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
      >
        <div className="px-1 py-1 ">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active
                    ? 'bg-yellow-02 bg-opacity-30 text-white'
                    : 'text-gray-900'
                } group flex rounded-md items-center w-full ${getSizeStyle(size).item}`}
              >
                Item 1
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active
                    ? 'bg-yellow-02 bg-opacity-30 text-white'
                    : 'text-gray-900'
                } group flex rounded-md items-center w-full ${getSizeStyle(size).item}`}
              >
                Item 2
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

DropdownMenu.defaultProps = {
  size: 'medium',
};

DropdownMenu.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default DropdownMenu;
