export const categories = [
  {
    id: 1,
    macro: 'Food & Beverage',
    icon: 'food_drinks',
    color: {
      bg: 'bg-red-100',
      text: 'text-red-800',
      border: 'border-red-800',
    },
    micro: [
      {
        id: 101,
        name: 'Street Food',
        icon: 'fb_street_food',
      },
      {
        id: 102,
        name: 'Beverages',
        icon: 'fb_beverages',
      },
      {
        id: 103,
        name: 'Desserts & Snacks',
        icon: 'fb_desserts_snacks',
      },
      {
        id: 104,
        name: 'Ethnic Cuisine',
        icon: 'fb_ethnic_cuisine',
      },
      {
        id: 105,
        name: 'Healthy Food',
        icon: 'fb_healthy_food',
      },
    ],
  },
  {
    id: 2,
    macro: 'Fashion & Apparel',
    icon: 'fashion_apparel',
    color: {
      bg: 'bg-green-100',
      text: 'text-green-800',
      border: 'border-green-800',
    },
    micro: [
      {
        id: 201,
        name: 'Casual Wear',
        icon: 'fa_casual_wear',
      },
      {
        id: 202,
        name: 'Footwear',
        icon: 'fa_footwear',
      },
      {
        id: 203,
        name: 'Accessories',
        icon: 'fa_accessories',
      },
      {
        id: 204,
        name: 'Vintage Clothing',
        icon: 'fa_vintage_clothing',
      },
      {
        id: 205,
        name: 'Sports and Activewear',
        icon: 'fa_sports_activewear',
      },
    ],
  },
  {
    id: 3,
    macro: 'Technology & Gadgets',
    icon: 'tech_gadgets',
    color: {
      bg: 'bg-purple-100',
      text: 'text-purple-800',
      border: 'border-purple-800',
    },
    micro: [
      {
        id: 301,
        name: 'Electronics and Smart Devices',
        icon: 'tg_electronics_smart_devices',
      },
      {
        id: 302,
        name: 'Internet and AI',
        icon: 'tg_internet_ai',
      },
      {
        id: 303,
        name: 'Robotics',
        icon: 'tg_robotics',
      },
      {
        id: 304,
        name: 'Gaming',
        icon: 'tg_gaming',
      },
      {
        id: 305,
        name: 'Other technologies',
        icon: 'tg_other_technologies',
      },
    ],
  },
  {
    id: 4,
    macro: 'Home & Lifestyle',
    icon: 'home_lifestyle',
    color: {
      bg: 'bg-yellow-100',
      text: 'text-yellow-800',
      border: 'border-yellow-800',
    },
    micro: [
      {
        id: 401,
        name: 'Home Decor',
        icon: 'hl_home_decor',
      },
      {
        id: 402,
        name: 'Furniture',
        icon: 'hl_furniture',
      },
      {
        id: 403,
        name: 'Outdoor Living',
        icon: 'hl_outdoor_living',
      },
      {
        id: 404,
        name: 'Kitchen Essentials',
        icon: 'hl_kitchen_essentials',
      },
      {
        id: 405,
        name: 'Bedding & Linens',
        icon: 'hl_bedding_linens',
      },
    ],
  },
  {
    id: 5,
    macro: 'Arts & Crafts',
    icon: 'arts_crafts',
    color: {
      bg: 'bg-blue-100',
      text: 'text-blue-800',
      border: 'border-blue-800',
    },
    micro: [
      {
        id: 501,
        name: 'Paintings & Prints',
        icon: 'ac_paintings_prints',
      },
      {
        id: 502,
        name: 'Handmade Jewelry',
        icon: 'ac_handmade_jewelry',
      },
      {
        id: 503,
        name: 'Sculptures',
        icon: 'ac_sculptures',
      },
      {
        id: 504,
        name: 'Pottery & Ceramics',
        icon: 'ac_pottery_ceramics',
      },
      {
        id: 505,
        name: 'Textile Arts',
        icon: 'ac_textile_arts',
      },
    ],
  },
];
