import React from 'react';
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from '@headlessui/react';
import { UserCircleIcon, BriefcaseIcon } from '@heroicons/react/20/solid';
import BrandForm from 'components/Register/BrandForm';
import PromoterForm from 'components/Register/PromoterForm';

const RegistrationPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-6xl text-center text-yellow-500 font-bold mb-4">
        Join Us
      </h1>
      <p className="text-lg text-gray-500 mb-8">
        Create an account to get started. Choose your role below.
      </p>
      <div className="w-full max-w-4xl bg-white shadow-xl rounded-lg p-8">
        <TabGroup>
          <TabList className="flex items-center justify-center p-2 space-x-4 rounded-full mb-8">
            <Tab
              className={({ selected }) =>
                `flex items-center justify-center px-8 py-4 text-lg font-medium rounded-full transition-colors duration-300 outline-none ${
                  selected
                    ? 'bg-yellow-500 text-white shadow-md border border-gray-300'
                    : 'bg-gray-200 text-gray-600'
                }`
              }
            >
              <BriefcaseIcon className="w-6 h-6 mr-2" />
              I'm a Brand
            </Tab>
            <Tab
              className={({ selected }) =>
                `flex items-center justify-center px-8 py-4 text-lg font-medium rounded-full transition-colors duration-300 outline-none ${
                  selected
                    ? 'bg-yellow-500 text-white shadow-xl border border-gray-300'
                    : 'bg-gray-200 text-gray-600'
                }`
              }
            >
              <UserCircleIcon className="w-6 h-6 mr-2" />
              I'm a Promoter
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="bg-gray-50 rounded-xl shadow-inner">
              <BrandForm />
            </TabPanel>
            <TabPanel className="bg-gray-50 rounded-xl shadow-inner">
              <PromoterForm />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default RegistrationPage;
