import React from 'react';
import StepIndicator from '../Indicator';

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="flex flex-col space-y-4">
      {steps.map((step, index) => (
        <StepIndicator
          key={index}
          step={index + 1}
          currentStep={currentStep}
          label={step.label}
        />
      ))}
    </div>
  );
};

export default Stepper;
