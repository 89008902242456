import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import '../../index.css';

const CustomSlider = ({ children, slidesPerView, isEvents }) => {
  return (
    <div className={`relative mx-auto py-4 ${isEvents ? 'w-1/2' : 'w-full'}`}>
      <Swiper
        modules={[Navigation]}
        spaceBetween={isEvents ? 8 : 16}
        slidesPerView={slidesPerView}
        navigation
        className="mySwiper"
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={index}>
            <div
              className={`rounded-3xl overflow-hidden ${isEvents ? '' : 'w-60 h-60'}`}
            >
              {child}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

CustomSlider.propTypes = {
  children: PropTypes.node.isRequired,
  slidesPerView: PropTypes.number,
  isEvents: PropTypes.bool,
};

export default CustomSlider;
