import React from 'react';
import { useSelector } from 'react-redux';
import { GlobeAltIcon } from '@heroicons/react/outline';
import { ClipLoader } from 'react-spinners';

import facebookIcon from '../../../assets/icons/socials/facebook_icon.svg';
import instagramIcon from '../../../assets/icons/socials/instagram_icon.svg';
import linkedinIcon from '../../../assets/icons/socials/linkedin_icon.svg';
import twitterIcon from '../../../assets/icons/socials/twitter_icon.svg';

const PromoterInfo = () => {
  const { promoter, loading, error } = useSelector((state) => state.promoter);

  if (loading) {
    return (
      <div className="flex justify-center py-10">
        <ClipLoader color="#FFBD59" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center py-10 text-red-500">
        Error: {error}
      </div>
    );
  }

  if (!promoter) {
    return (
      <div className="flex justify-center py-10 text-gray-400">
        No promoter information available.
      </div>
    );
  }

  const icons = {
    facebook: facebookIcon,
    instagram: instagramIcon,
    linkedin: linkedinIcon,
    twitter: twitterIcon,
  };

  const renderDetailInfo = () => {
    return (
      <div className="grid grid-cols-2 gap-6">
        {/* Each info block */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">
            Year of Foundation
          </h3>
          <p className="text-gray-4 00">{promoter.foundationYear || 'N/A'}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">
            Country of Origin
          </h3>
          <p className="text-gray-4 00">{promoter.country || 'N/A'}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">
            City of Origin
          </h3>
          <p className="text-gray-4 00">{promoter.city || 'N/A'}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">
            Fiscal Number
          </h3>
          <p className="text-gray-4 00">{promoter.fiscalNumber || 'N/A'}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">Firm</h3>
          <p className="text-gray-4 00">{promoter.firm || 'N/A'}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold text-yellow-03">Email</h3>
          <p className="text-gray-4 00">{promoter.email || 'N/A'}</p>
        </div>
      </div>
    );
  };

  const renderSocialMedia = () => {
    return (
      <div className="mt-10">
        <h3 className="text-lg font-semibold text-yellow-03 mb-2">
          Social Media Links
        </h3>
        <div className="flex space-x-6">
          {promoter.socialMedia?.map((social, idx) => (
            <a
              key={idx}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 text-sm hover:text-blue-500 transition-all duration-200"
            >
              <img
                src={icons[social.name.toLowerCase()] || GlobeAltIcon}
                alt={`${social.name} icon`}
                className="h-6 w-6"
              />
              <span>{social.name}</span>
            </a>
          ))}
        </div>
      </div>
    );
  };

  const renderProfileInfo = () => {
    return (
      <div className="mt-10">
        <h3 className="text-lg font-semibold text-yellow-03 mb-2">About</h3>
        <p className="text-gray-700 leading-relaxed">
          {promoter.profileInfo ||
            'This promoter has not provided a profile description yet.'}
        </p>
      </div>
    );
  };

  return (
    <div className="w-full bg-white rounded-br-3xl shadow-lg p-8">
      {renderDetailInfo()}
      {renderSocialMedia()}
      {renderProfileInfo()}
    </div>
  );
};

export default PromoterInfo;
