import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ current, total, containerStyle }) => {
  const fillPercentage = total > 0 ? (current / total) * 100 : 0;

  const getCurrentLabel = () => {
    if (fillPercentage === 0) {
      return 'Empty';
    }
    if (fillPercentage < 30) {
      return 'Almost Empty';
    } else if (fillPercentage < 70) {
      return 'Halfway';
    } else {
      return 'Almost Full';
    }
  };

  return (
    <div className={containerStyle}>
      <p className="text-xs text-red-500">{getCurrentLabel()}</p>
      <div className="w-full bg-gray-200 rounded-full h-4">
        <div
          className="bg-gray-400 h-4 rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${fillPercentage}%` }}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  containerStyle: PropTypes.string,
};

export default ProgressBar;
