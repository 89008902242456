import React, { useState } from 'react';

import Listbox from 'components/Listbox';

import { MOCK_TOPICS } from 'mockData';

const Help = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [message, setMessage] = useState('');

  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      <div className="flex flex-col py-10 px-40 justify-center items-center">
        <h3 className="pb-6 text-3xl font-bold text-yellow-02">Talk to us!</h3>
        <div className="w-full ">
          <Listbox data={MOCK_TOPICS} action={setSelectedTopic} />
          <textarea
            className="w-full h-80 bg-gray-100 rounded-lg p-4 mt-4 overflow-y-auto outline-none resize-none"
            placeholder="Write a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
