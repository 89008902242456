import React from 'react';
import DropdownMenu from 'components/DropdownMenu';
import SelectListbox from 'components/Listbox';
import AutoCompleteCombobox from 'components/Combobox';
import ToggleSwitch from 'components/ToggleSwitch';
import SimpleDisclosure from 'components/Disclosure';
import ModalDialog from 'components/DialogModal';
import SimplePopover from 'components/SimplePopover';
import RadioGroupExample from 'components/RadioGroup';
import TabsComponent from 'components/Tabs';
import Datepicker from 'components/Datepicker';
import ProfileButton from 'components/ProfileButton';

import { MOCK_PRICE_RANGES } from '../mockData';

const Showroom = () => {
  return (
    <>
      <div className="flex flex-row justify-center space-x-8 p-4">
        <div className="flex flex-col">
          <h1 className="text-xl font-bold">Component Showroom</h1>
          <div className="border rounded mb-2 p-4 justify-center">
            <h3 className="text-lg font-semibold py-6">Dropdown Menus</h3>
            <div className="flex flex-row items-center space-x-5">
              <DropdownMenu size="small" />
              <DropdownMenu />
              <DropdownMenu size="large" />
            </div>
          </div>
          <div className="border rounded mb-2 p-4 items-center">
            <h3 className="text-lg font-semibold">Listbox</h3>
            <SelectListbox data={MOCK_PRICE_RANGES} />
          </div>
          <div className="border rounded mb-2 p-4 ">
            <h3 className="text-lg font-semibold py-6">Combobox</h3>
            <AutoCompleteCombobox />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Toggle Switch</h3>
            <ToggleSwitch />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Disclosure</h3>
            <SimpleDisclosure />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Dialog</h3>
            <ModalDialog isOpen={false} />
          </div>
        </div>
        <div className="flex flex-col p-6">
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Popup</h3>
            <SimplePopover />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Radio</h3>
            <RadioGroupExample />
          </div>
        </div>
        <div className="flex flex-col p-6">
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Tabs</h3>
            <TabsComponent />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">Datepicker</h3>
            <Datepicker onDateChange={() => console.log('sdasdasdasd')} />
          </div>
          <div className="border rounded mb-2 p-4">
            <h3 className="text-lg font-semibold py-6">ProfileButton</h3>
            <ProfileButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default Showroom;
