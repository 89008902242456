export const getLowestPrice = (spaces) => {
  if (!spaces || spaces.length === 0) {
    console.error('Spaces array is empty or undefined.');
    return null;
  }

  const lowestPrice = Math.min(...spaces.map((space) => Number(space.price)));

  return lowestPrice;
};
