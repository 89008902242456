import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  ChevronDownIcon,
  InboxIcon,
  UserIcon,
  CogIcon,
} from '@heroicons/react/24/outline';

export const SimplePopover = () => (
  <Popover className="relative">
    <Popover.Button className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-black rounded-md">
      Options
      <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
    </Popover.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl">
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
            {/* make some mock content with */}
            <a
              href="#"
              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
            >
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-blue-500 rounded-md sm:h-12 sm:w-12">
                <InboxIcon className="w-6 h-6" aria-hidden="true" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Inbox</p>
                <p className="text-sm text-gray-500">
                  Get all your messages here.
                </p>
              </div>
            </a>
            <a
              href="#"
              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
            >
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-green-500 rounded-md sm:h-12 sm:w-12">
                <UserIcon className="w-6 h-6" aria-hidden="true" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Profile</p>
                <p className="text-sm text-gray-500">
                  View or edit your profile.
                </p>
              </div>
            </a>
            <a
              href="#"
              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
            >
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-purple-500 rounded-md sm:h-12 sm:w-12">
                <CogIcon className="w-6 h-6" aria-hidden="true" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Settings</p>
                <p className="text-sm text-gray-500">Customize your setup.</p>
              </div>
            </a>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
);

export default SimplePopover;
