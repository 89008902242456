import axios from '../../utils/axiosConfig';

// Action Types

export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL';

export const LIST_BRANDS_REQUEST = 'LIST_BRANDS_REQUEST';
export const LIST_BRANDS_SUCCESS = 'LIST_BRANDS_SUCCESS';
export const LIST_BRANDS_FAIL = 'LIST_BRANDS_FAIL';

export const getBrand = (brandId) => async (dispatch, getState) => {
  dispatch({ type: GET_BRAND_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get(`/brands/profile/${brandId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BRAND_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_BRAND_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch brand',
    });
  }
};

export const listBrands = () => async (dispatch, getState) => {
  dispatch({ type: LIST_BRANDS_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get('/brands', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: LIST_BRANDS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LIST_BRANDS_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch brands',
    });
  }
};
