import React from 'react';
import PropTypes from 'prop-types';

const Rating = ({ rating }) => {
  return (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <svg
          key={i}
          className={`h-4 w-4 ${
            i < rating ? 'text-yellow-400' : 'text-gray-300'
          }`}
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 .587l3.163 9.412h9.837l-7.95 5.764 3.163 9.412-7.95-5.763-7.95 5.763 3.162-9.412-7.95-5.764h9.837z" />
        </svg>
      ))}
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default Rating;
