import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Bars3Icon,
  UserCircleIcon,
  UserIcon,
  InboxIcon,
  BellIcon,
  CogIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';

const ProfileButton = ({ avatarUrl, notificationCount, onLogout }) => {
  const handleProfileClick = () => {
    console.log('Profile button clicked!');
  };

  const renderButton = () => {
    return (
      <button
        onClick={handleProfileClick}
        className="flex items-center px-4 py-2 border border-gray-400 rounded-full hover:border-gray-600 focus:outline-none focus:border-gray-400 transition duration-200 relative"
      >
        <Bars3Icon className="h-6 w-6 text-lg text-gray-400" />
        {avatarUrl ? (
          <div className="relative ml-4">
            <img
              src={avatarUrl}
              alt="User Avatar"
              className="h-10 w-10 rounded-full"
            />
            {notificationCount > 0 && (
              <span className="absolute -top-1 -right-1 inline-flex items-center justify-center h-4 w-4 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                {notificationCount}
              </span>
            )}
          </div>
        ) : (
          <UserCircleIcon className="h-8 w-8 ml-4 text-sm text-yellow-03" />
        )}
      </button>
    );
  };

  return (
    <Popover className="relative">
      <PopoverButton as={Fragment} className="inline-flex items-center">
        {renderButton()}
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute z-10 mt-2 origin-top-right right-0">
          <div className="overflow-hidden px-8 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="bg-white p-4">
              <PopoverButton
                as="a"
                href="/dashboard"
                className="flex items-center py-6 px-2 -m-3 transition duration-150 ease-in-out border-b border-b-gray-400 hover:bg-gray-50"
              >
                <UserIcon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
                <span className="ml-3 text-sm space-x-8font-medium text-gray-900">
                  Dashboard
                  {notificationCount > 0 && (
                    <span className="ml-4 h-4 w-4 text-xs px-2 py-1 font-bold leading-none text-white bg-red-600 rounded-full">
                      {notificationCount}
                    </span>
                  )}
                </span>
              </PopoverButton>
              <PopoverButton
                as="a"
                href="#"
                className="flex items-center py-4 px-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
              >
                <InboxIcon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
                <span className="ml-3 text-sm font-medium text-gray-900">
                  Messages
                </span>
              </PopoverButton>
              <PopoverButton
                as="a"
                href="#"
                className="flex items-center py-4 px-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
              >
                <BellIcon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
                <span className="ml-3 text-sm font-medium text-gray-900">
                  Notifications
                </span>
              </PopoverButton>
              <PopoverButton
                as="a"
                href="#"
                className="flex items-center py-4 px-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
              >
                <CogIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
                <span className="ml-3 text-sm font-medium text-gray-900">
                  Settings
                </span>
              </PopoverButton>
              <PopoverButton
                as="button"
                onClick={onLogout}
                className="flex items-center py-4 px-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
              >
                <ArrowRightOnRectangleIcon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
                <span className="ml-3 text-sm font-medium text-gray-900">
                  Logout
                </span>
              </PopoverButton>
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

ProfileButton.propTypes = {
  avatarUrl: PropTypes.string,
  notificationCount: PropTypes.number,
  onLogout: PropTypes.func,
};

ProfileButton.defaultProps = {
  notificationCount: 0,
};

export default ProfileButton;
