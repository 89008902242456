import axios from '../../utils/axiosConfig';

// Redux Actions for both Brands and Promoters
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const AUTH_ERROR = 'AUTH_ERROR';
const LOGOUT = 'LOGOUT';

export const login = (email, password) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post('/login', body);

    // Save token and user data in localStorage
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('user', JSON.stringify(res.data.user));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.data.message,
    });
    throw new Error(err.response.data.message);
  }
};

export const register = (role, formData) => async (dispatch) => {
  try {
    const res = await axios.post(`/${role}/register`, formData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.message,
    });
  }
};

export const checkAuth = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return dispatch({
      type: AUTH_ERROR,
      payload: 'No token found',
    });
  }

  try {
    const res = await axios.get('/auth/check', {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Store the user info after successful auth check
    localStorage.setItem('user', JSON.stringify(res.data.user));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.message,
    });
  }
};

export const logout = () => (dispatch) => {
  // Clear token and user data from localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  dispatch({ type: LOGOUT });
};
