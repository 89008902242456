import { useState, useEffect } from 'react';

export const useImage = (src, crossOrigin) => {
  const [image, setImage] = useState(null);

  const proxiedSrc = 'https://corsproxy.io/?' + encodeURIComponent(src);

  useEffect(() => {
    const img = new window.Image();
    if (crossOrigin) {
      img.crossOrigin = crossOrigin;
    }
    img.src = proxiedSrc;
    img.onload = () => setImage(img);
  }, [proxiedSrc, crossOrigin]);

  return [image];
};
