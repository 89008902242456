import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

const StepIndicator = ({ step, currentStep, label }) => {
  const isCurrentStep = currentStep === step;
  const isCompletedStep = currentStep > step;

  return (
    <div className="relative flex">
      <div className="flex flex-col items-center">
        <div
          className={`w-8 h-8 flex items-center justify-center rounded-full border ${
            isCurrentStep
              ? 'border-yellow-500 bg-yellow-500 text-white'
              : isCompletedStep
                ? 'border-yellow-03 bg-white'
                : 'border-gray-300 bg-white'
          }`}
        >
          {isCompletedStep ? (
            <CheckIcon className="p-2 text-yellow-03" />
          ) : (
            step
          )}
        </div>
        {step !== 5 && (
          <div
            className={`w-px h-8 mt-4 ${isCompletedStep ? 'bg-yellow-03' : 'bg-gray-300'}`}
          ></div>
        )}
      </div>
      <div
        className={`ml-4 pt-1 ${isCurrentStep ? 'font-bold text-yellow-500' : isCompletedStep ? 'text-yellow-03' : 'text-gray-500'}`}
      >
        {label}
      </div>
    </div>
  );
};

export default StepIndicator;
