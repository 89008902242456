import axios from 'axios';
import store from '../redux/store';
import { logout } from '../redux/actions/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add response interceptor to handle token expiration globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Dispatch logout if token is invalid or expired
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
