import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the CSS for the calendar
import './DatepickerStyles.css'; // Assuming you have custom styles

const DatePicker = ({ onDateChange }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [datesSelected, setDatesSelected] = useState(false);

  const handleDateChange = (value) => {
    const [from, to] = value;
    setFromDate(from);
    setToDate(to);
    setDatesSelected(true);
    onDateChange({
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    });
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            className={`${
              open ? '' : 'text-opacity-90 border border-gray-200'
            } flex items-center px-4 py-3 bg-white text-gray-400 text-sm rounded-full`}
          >
            <span className="mr-2">
              <CalendarDaysIcon className="h-4 w-4 text-gray-400" />
            </span>
            {datesSelected
              ? `${moment(fromDate).format('MMM DD')} - ${moment(toDate).format('MMM DD')}`
              : 'When'}
          </PopoverButton>

          <PopoverPanel className="absolute z-10 mt-2 border border-gray-200">
            <div className="bg-white rounded-xl shadow-lg">
              <Calendar
                className="bg-white rounded-xl shadow-lg text-gray-600 text-sm ring-1 ring-yellow-01 ring-opacity-50"
                onChange={handleDateChange}
                selectRange={true}
                value={[fromDate, toDate]}
              />
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

DatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
};

export default DatePicker;
