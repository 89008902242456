import React from 'react';
import PropTypes from 'prop-types';

import { MapPinIcon } from '@heroicons/react/24/solid';

const MapButton = ({ action, text = 'Show on Map', hasIcon }) => {
  return (
    <button
      className="flex items-center space-x-2 bg-yellow-02 text-white px-4 py-3 rounded-3xl shadow-[rgba(0,0,15,0.5)_-3px_3px_4px_0px] text-sm font-bold"
      onClick={action}
    >
      {hasIcon && <MapPinIcon className="h-6 w-6 text-gray-400" />}
      <span>{text}</span>
    </button>
  );
};

MapButton.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string,
  hasIcon: PropTypes.bool,
};

export default MapButton;
