import React from 'react';
import PropTypes from 'prop-types';
import { CheckIcon } from '@heroicons/react/24/solid';

const BenefitsCard = ({ title, items }) => {
  return (
    <div className="bg-white p-6 rounded-3xl shadow-2xl h-[400px] w-1/4">
      <h3 className={`text-center text-2xl text-black mb-8`}>
        I’m a <span className={`text-yellow-04 font-bold`}>{title}</span>
      </h3>
      <ul className="text-gray-600 text-lg list-disc list-inside space-y-4">
        {items.map((item, idx) => (
          <li key={idx} className="flex items-center">
            <CheckIcon className="h-6 w-6 text-gray-400 mr-2" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

BenefitsCard.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BenefitsCard;
