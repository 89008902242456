import * as Yup from 'yup';

const urlRegex =
  /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

const fileValidation = Yup.mixed()
  .nullable()
  .test('fileSize', 'The file is too large', (value) => {
    if (!value) return true; // no file, don't fail
    return value.size <= 1024 * 1024; // 1MB
  });

const fileArrayValidation = Yup.array()
  .of(fileValidation)
  .max(10, 'You can only upload up to 10 pictures')
  .nullable(true);

export const brandSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  description: Yup.string(),
  foundingYear: Yup.number().positive().integer(),
  macroCategory: Yup.array()
    .of(Yup.string())
    .required('At least one macro category is required'),
  microCategory: Yup.array().of(Yup.string()),
  website: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  facebookUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  twitterUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  instagramUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  linkedinUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  avatar: Yup.mixed().test(
    'fileSize',
    'The file is too large',
    (value) => !value || (value && value.size <= 1024 * 1024)
  ),
  media: Yup.array()
    .of(
      Yup.mixed().test(
        'fileSize',
        'One or more files are too large',
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
    )
    .max(6, 'You can only upload up to 6 media files'),
});

export const promoterSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  // password must be at least 8 characters have at least one uppercase letter, one lowercase letter, one number and one special character
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  registeredDate: Yup.date(),
  lastLoginDate: Yup.date(),
  profileInfo: Yup.string(),
  notificationSettings: Yup.object().shape({
    // Define the shape if you have known properties or leave it as an empty object
  }),
  resetToken: Yup.string(),
  avatar: Yup.mixed().test('fileSize', 'The file is too large', (value) => {
    // Allow the field to be optional (skip validation if no file is uploaded)
    if (!value || value.length === 0) return true;
    // Check file size (assuming limit is 2MB)
    return value[0].size <= 2000000; // 2MB
  }),
  companyName: Yup.string(),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  fiscalNumber: Yup.number().required('Fiscal number is required'),
  firm: Yup.string(),
  headquarters: Yup.string(),
  website: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  facebookUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  twitterUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  instagramUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
  linkedinUrl: Yup.string().matches(urlRegex, 'Invalid URL').nullable(true),
});

export const eventSchema = [
  // Step 1: Event Details
  Yup.object().shape({
    name: Yup.string().required('Event name is required'),
    location: Yup.string().required('Location is required'),
    date: Yup.object()
      .shape({
        from: Yup.date('Event start date is required').required(
          'Event start date is required'
        ),
        to: Yup.date().required('Event end date is required'),
        openingHours: Yup.string().required('Opening hours are required'),
        closingHours: Yup.string().required('Closing hours are required'),
      })
      .required(),
    categoriesAllowed: Yup.array()
      .of(
        Yup.object().shape({
          macroCategoryId: Yup.number().required(),
          microCategoryIds: Yup.array().of(Yup.number()).nullable(true),
        })
      )
      .required('At least one category is required'),
    longDescription: Yup.string().required('Long description is required'),
    shortDescription: Yup.string().required('Short description is required'),
    segment: Yup.object()
      .shape({
        segmentDate: Yup.date(),
        segmentOpeningHours: Yup.string(),
        segmentClosingHours: Yup.string(),
      })
      .nullable(true),
  }),

  // Step 2: Media & Amenities
  Yup.object().shape({
    eventIcon: fileValidation,
    mainImage: fileValidation,
    morePictures: fileArrayValidation,
    regulatoryDocument: fileValidation,
    utilities: Yup.array().of(Yup.string()),
  }),

  // Step 3: Space Creation
  Yup.object().shape({
    spaces: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          size: Yup.number().positive().integer().required('Size is required'),
          description: Yup.string().required('Description is required'),
          available: Yup.number()
            .positive()
            .integer()
            .required('Availability is required'),
          price: Yup.number().positive().required('Price is required'),
        })
      )
      .min(1, 'At least one space is required')
      .required(),
  }),

  // Step 4: Floor Planning
  Yup.object().shape({
    createFloorplan: Yup.boolean(),
    floorplanImage: Yup.mixed()
      .when('createFloorplan', {
        is: true,
        then: fileValidation.required('Floorplan image is required'),
      })
      .nullable(true),
  }),

  // Step 5: Summary
  Yup.object().shape({
    // No specific validations needed here as it's just a summary step
  }),
];
