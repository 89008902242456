import React from 'react';

import { EVENT_LIST } from 'mockData';

const Media = () => {
  const renderImage = (url) => (
    <div className="relative w-60 h-60">
      <img
        src={url}
        alt="Media Item"
        className="w-full h-full object-cover rounded-3xl bg-red-200"
      />
    </div>
  );

  const renderImages = (items) => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {items.map((item, idx) => (
          <div key={idx}>{renderImage(item.image)}</div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      {renderImages(EVENT_LIST)}
    </div>
  );
};

export default Media;
