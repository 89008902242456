import React, { useState, useEffect } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { register } from '../../../redux/actions/auth.js';
import { useModal } from '../../../context/ModalContext';
import { brandSchema } from 'utils/validationSchema';
import { categories } from 'utils/categories';
import {
  UserIcon,
  GlobeAltIcon,
  CalendarDaysIcon,
  CameraIcon,
  ChevronDownIcon,
  XCircleIcon,
  PlusIcon,
  LockClosedIcon,
} from '@heroicons/react/16/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import CategoryIcon from 'components/CategoryIcon';
import ImageCropper from 'components/ImageCropper';
import { useImageCropper } from 'hooks/useImageCropper'; // Custom hook

import './slider.css';
import 'react-datepicker/dist/react-datepicker.css';

export const BrandForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productImagesPreview, setProductImagesPreview] = useState([]);
  const [selectedMacros, setSelectedMacros] = useState([]);
  const [microOptions, setMicroOptions] = useState([]);
  const [selectedMicroCategories, setSelectedMicroCategories] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [foundingYear, setFoundingYear] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoginModal } = useModal();

  // Using the custom hook
  const {
    imagePreview,
    croppedImage: croppedLogo,
    isCropping,
    handleImageChange,
    handleCropSave,
    setIsCropping,
    resetImage,
  } = useImageCropper();

  const {
    handleSubmit,
    register: formRegister,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(brandSchema),
  });

  const selectedMacroCategories = watch('macroCategory');

  useEffect(() => {
    formRegister('macroCategory');
    formRegister('microCategory');
  }, [formRegister]);

  useEffect(() => {
    const selectedCategories = categories.filter((category) =>
      selectedMacroCategories?.includes(category.macro)
    );
    const micros = selectedCategories.flatMap((category) => category.micro);
    setMicroOptions(micros);

    const defaultSelectedMicros = selectedCategories.flatMap((category) =>
      category.micro.map((micro) => micro.name)
    );
    setSelectedMicroCategories(defaultSelectedMicros);
  }, [selectedMacroCategories]);

  const handleMacroChange = (e) => {
    const values = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setValue('macroCategory', values);
    setSelectedMacros(values);
    setSelectedMicroCategories([]);
  };

  const handleProductImagesChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const newImages = files.slice(0, 10);
      const previews = newImages.map((file) => ({
        id: URL.createObjectURL(file),
        file: file,
        preview: URL.createObjectURL(file),
      }));
      setProductImagesPreview((prevImages) => [
        ...prevImages,
        ...previews.slice(0, 10 - prevImages.length),
      ]);
    }
  };

  const handleRemoveImage = (id) => {
    setProductImagesPreview((prevImages) =>
      prevImages.filter((image) => image.id !== id)
    );
  };

  const handleDateChange = (date) => {
    const year = moment(date).format('YYYY');
    setFoundingYear(year);
  };

  const handleMicroChange = (microName) => {
    setSelectedMicroCategories((prev) =>
      prev.includes(microName)
        ? prev.filter((selected) => selected !== microName)
        : [...prev, microName]
    );
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim() && !keywords.includes(newKeyword.trim())) {
      setKeywords((prev) => [...prev, newKeyword.trim()]);
      setNewKeyword(''); // Clear input after adding
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords((prev) =>
      prev.filter((keyword) => keyword !== keywordToRemove)
    );
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const value = data[key];

      // Handle specific serialization for categories
      if (key === 'macroCategory' || key === 'microCategory') {
        formData.append(key, JSON.stringify(value));
      } else if (value instanceof FileList) {
        Array.from(value)
          .slice(0, 6)
          .forEach((file) => {
            formData.append(key, file, file.name);
          });
      } else if (typeof value === 'object' && !(value instanceof Date)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    if (foundingYear) {
      formData.append('foundingYear', foundingYear);
    }

    if (croppedLogo) {
      const response = await fetch(croppedLogo);
      const blob = await response.blob();
      formData.append('logo', blob, 'logo.jpg');
    }

    formData.append('keywords', JSON.stringify(keywords));
    try {
      await dispatch(register('brands', formData));
      navigate('/');
      showLoginModal();
    } catch (error) {
      console.error('Error submitting brand registration:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-8 py-10 bg-white shadow-md rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col space-y-1 w-1/2">
            {/* Brand Name */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <UserIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('username')}
                  placeholder="Enter your brand name"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.username && (
                <p className="text-red-500 text-sm">
                  {errors.username.message}
                </p>
              )}
            </div>

            {/* Email */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <UserIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('email')}
                  type="email"
                  placeholder="Enter your email"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>

            {/* Password */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <LockClosedIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('password')}
                  type="password"
                  placeholder="Enter your password"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>

            {/* Confirm Password */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <LockClosedIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('confirmPassword')}
                  type="password"
                  placeholder="Confirm your password"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
          </div>

          {/* Logo Upload */}
          <div className="flex flex-col w-1/4 items-center">
            <div className="relative w-24 h-24">
              <label className="text-gray-700 font-medium">Upload Logo</label>
              <input
                type="file"
                id="logo-upload"
                accept="image/*"
                className="sr-only"
                onChange={handleImageChange} // Use the hook handler
              />
              <label
                htmlFor="logo-upload"
                className="cursor-pointer relative bg-white"
              >
                {croppedLogo ? (
                  <div className="relative">
                    <img
                      src={croppedLogo}
                      alt="Logo Preview"
                      className="w-24 h-24 rounded-full object-cover"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        resetImage(); // Reset the cropped image and close the modal
                        document.getElementById('logo-upload').value = ''; // Reset file input
                      }}
                      className="absolute top-0 right-0 bg-white rounded-full p-1 shadow hover:bg-gray-100"
                    >
                      <XCircleIcon className="w-6 h-6 text-red-500" />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
                    <UserIcon className="w-10 h-10 text-gray-500" />
                  </div>
                )}
              </label>
            </div>
            {errors.logo && (
              <p className="text-red-500 text-sm">{errors.logo.message}</p>
            )}
          </div>
        </div>

        {/* Image Cropper */}
        {isCropping && imagePreview && (
          <ImageCropper
            imageSrc={imagePreview}
            aspect={1}
            onCropSave={handleCropSave}
            onClose={() => setIsCropping(false)}
          />
        )}

        {/* Product Images Upload */}
        <div className="flex flex-col space-y-1 pt-4">
          <input
            type="file"
            multiple
            id="product-images-upload"
            accept="image/*"
            className="sr-only"
            onChange={handleProductImagesChange}
          />
          <label
            htmlFor="product-images-upload"
            className="flex items-center justify-center px-4 py-2 bg-yellow-500 text-white rounded-lg cursor-pointer hover:bg-yellow-600 focus:outline-none outline-none"
          >
            <CameraIcon className="w-6 h-6 mr-2" />
            Upload Brand Images
          </label>

          {/* Display Uploaded Product Images */}
          <div className="mt-4 flex space-x-2 overflow-x-auto">
            {productImagesPreview.map((image, index) => (
              <div key={image.id} className="relative flex-shrink-0">
                <img
                  src={image.preview}
                  alt="Product Images"
                  className="w-24 h-24 object-cover rounded-md border"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(image.id)}
                  className="absolute top-0 right-0 bg-white rounded-full p-1 shadow hover:bg-gray-100"
                >
                  <XCircleIcon className="w-6 h-6 text-red-500" />
                </button>
              </div>
            ))}
          </div>
          {errors.productImages && (
            <p className="text-red-500 text-sm">
              {errors.productImages.message}
            </p>
          )}
        </div>

        {/* City */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <GlobeAltIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('city')}
              placeholder="Enter your city of origin"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.city && (
            <p className="text-red-500 text-sm">{errors.city.message}</p>
          )}
        </div>

        {/* Country */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <GlobeAltIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('country')}
              placeholder="Enter your country"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country.message}</p>
          )}
        </div>

        {/* Founding Year */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <CalendarDaysIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <ReactDatePicker
              selected={
                foundingYear ? moment(foundingYear, 'YYYY').toDate() : null
              }
              onChange={handleDateChange}
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select founding year"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
              maxDate={new Date()}
              popperPlacement="top-end"
            />
          </div>
        </div>

        {/* Description */}
        <div className="flex flex-col space-y-1">
          <textarea
            {...formRegister('description')}
            placeholder="Describe your brand"
            className="border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
          />
          {errors.description && (
            <p className="text-red-500 text-sm">{errors.description.message}</p>
          )}
        </div>

        {/* Website */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <GlobeAltIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('website')}
              placeholder="Enter your website URL"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.website && (
            <p className="text-red-500 text-sm">{errors.website.message}</p>
          )}
        </div>

        {/* Macro Categories */}
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-1">
            <label className="text-gray-700 font-medium">
              Macro Categories
            </label>

            <div className="relative group">
              <InformationCircleIcon className="w-3 h-3 text-gray-400 cursor-pointer" />
              <div className="absolute z-50 left-1/2 -translate-x-1/2 mt-2 w-48 p-2 text-xs bg-gray-500 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                Select the macro categories that your brand falls under. You can
                select multiple options.
              </div>
            </div>
          </div>

          <Listbox
            multiple
            value={selectedMacros}
            onChange={(values) => {
              handleMacroChange({
                target: { selectedOptions: values.map((value) => ({ value })) },
              });
              setSelectedMacros(values);
            }}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <ListboxButton className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none outline-none">
                    <div className="flex flex-wrap gap-2">
                      {selectedMacros.length > 0 ? (
                        selectedMacros.map((macro) => {
                          const selectedCategory = categories.find(
                            (category) => category.macro === macro
                          );
                          return (
                            <span
                              key={macro}
                              className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${selectedCategory.color.bg} ${selectedCategory.color.text}`}
                            >
                              <CategoryIcon
                                macro={selectedCategory.icon} // Assuming you use the icon from the 'icon' field
                                className="w-4 h-4 mr-2"
                              />
                              {macro}
                            </span>
                          );
                        })
                      ) : (
                        <span className="text-gray-400">
                          Select Macro Categories
                        </span>
                      )}
                    </div>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <ListboxOptions className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {categories.map((category) => (
                      <ListboxOption
                        key={category.id}
                        value={category.macro}
                        className={({ active }) =>
                          `cursor-default select-none relative py-2 pl-10 pr-4 ${
                            active
                              ? 'text-yellow-900 bg-yellow-100'
                              : 'text-gray-900'
                          }`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <CategoryIcon
                                macro={category.icon}
                                className="w-6 h-6 mr-2"
                              />
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {category.macro}
                              </span>
                            </div>
                          </>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
                <p className="text-gray-500 text-sm mt-1">
                  You can select multiple macro categories
                </p>
              </>
            )}
          </Listbox>

          {errors.macroCategory && (
            <p className="text-red-500 text-sm">
              {errors.macroCategory.message}
            </p>
          )}
        </div>

        {/* Micro Categories */}
        {selectedMacros.length > 0 && (
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-1">
              <label className="text-gray-700 font-medium">
                Micro Categories
              </label>

              <div className="relative group">
                <InformationCircleIcon className="w-3 h-3 text-gray-400 cursor-pointer" />
                <div className="absolute z-50 left-1/2 -translate-x-1/2 mt-2 w-48 p-2 text-xs bg-gray-500 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                  Deselect the micro categories that do not apply to your brand.
                </div>
              </div>
            </div>

            {/* Iterate over selected macros */}
            {selectedMacros.map((macro) => {
              const selectedCategory = categories.find(
                (category) => category.macro === macro
              );

              return (
                <div key={selectedCategory.id}>
                  {/* Display micro categories in one row */}
                  <div className="flex flex-wrap gap-3">
                    {selectedCategory.micro.map((micro) => {
                      const isSelected = selectedMicroCategories.includes(
                        micro.name
                      );

                      return (
                        <button
                          key={micro.name}
                          type="button"
                          onClick={() => handleMicroChange(micro.name)}
                          className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium border transition-all ${
                            isSelected
                              ? `${selectedCategory.color.text} ${selectedCategory.color.border} border-transparent`
                              : 'border-gray-300 text-gray-500 bg-transparent'
                          } hover:shadow-md text-sm font-medium`}
                        >
                          <div
                            className={`flex-shrink-0 w-5 h-5 mr-2 flex items-center justify-center rounded-full border ${
                              isSelected
                                ? `${selectedCategory.color.text} border-${selectedCategory.color.text}`
                                : 'text-gray-400 border-gray-300'
                            }`}
                          >
                            <CategoryIcon
                              macro={selectedCategory.icon}
                              micro={micro.icon}
                              className={`w-4 h-4 ${
                                isSelected
                                  ? selectedCategory.color.text
                                  : 'text-gray-500'
                              }`}
                            />
                          </div>
                          {micro.name}
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Keywords */}
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-1">
            <label className="text-gray-700 font-medium">Keywords</label>

            <div className="relative group">
              <InformationCircleIcon className="w-3 h-3 text-gray-400 cursor-pointer" />

              <div className="absolute z-50 left-1/2 -translate-x-1/2 mt-2 w-48 p-2 text-xs bg-gray-500 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                Add keywords that best describe your brand and its offerings.
                These keywords will help users find your brand more easily in
                search results.
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              placeholder="Enter a keyword"
              className="border border-gray-300 focus:outline-none rounded-md shadow-sm p-2 w-1/2 outline-none"
            />
            <button
              type="button"
              onClick={handleAddKeyword}
              className="bg-yellow-500 text-white p-2 rounded-full hover:bg-yellow-600"
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {keywords.map((keyword, index) => (
              <div
                key={index}
                className="inline-flex items-center bg-gray-200 text-gray-700 pl-3 pr-2 py-1 rounded-full"
              >
                {keyword}
                <button
                  type="button"
                  onClick={() => handleRemoveKeyword(keyword)}
                  className="ml-2 text-red-500"
                >
                  <XCircleIcon className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center pt-8">
          <button
            type="submit"
            className="bg-yellow-500 text-white py-3 px-12 rounded-full text-lg transition-all duration-300 hover:bg-yellow-600"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ClipLoader color="#FFFFFF" size={24} />
            ) : (
              'Register'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BrandForm;
