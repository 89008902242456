import React from 'react';
import PropTypes from 'prop-types';

import {
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  ViewfinderCircleIcon,
  ArrowsUpDownIcon,
  ArrowsRightLeftIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline';

const Toolbar = ({
  isSelectMode,
  onSelectMode,
  onUndo,
  onAlignHorizontal,
  onAlignVertical,
  onReset,
  onAlignEvenlyHorizontally,
  onAlignEvenlyVertically,
  onFinish,
}) => {
  return (
    <div className="flex flex-row justify-center space-x-8 p-4">
      <button onClick={onSelectMode}>
        <ViewfinderCircleIcon
          className={`w-6 h-6 border border-gray-600 ${isSelectMode ? 'text-yellow-03' : 'text-black'}`}
        />
      </button>
      <button onClick={onAlignEvenlyHorizontally}>
        <ArrowsRightLeftIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onAlignEvenlyVertically}>
        <ArrowsUpDownIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onAlignHorizontal}>
        <AdjustmentsHorizontalIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onAlignVertical}>
        <AdjustmentsVerticalIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onUndo}>
        <ArrowUturnLeftIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onReset}>
        <ArrowPathIcon className="w-6 h-6 border border-gray-600" />
      </button>
      <button onClick={onFinish}>
        <ArrowRightStartOnRectangleIcon className="w-6 h-6 border border-gray-600" />
      </button>
    </div>
  );
};

Toolbar.propTypes = {
  onAddShape: PropTypes.func,
};

export default Toolbar;
