import React, { useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, TabGroup, TabPanels, TabPanel } from '@headlessui/react';

import EventSearchBar from 'components/EventSearchBar';
import EventCardWrapper from 'components/EventCardWrapper';
import MapButton from 'components/MapButton';
import BrandImageCarousel from 'components/BrandImageCarousel';
import BenefitsCard from 'components/Homepage/BenefitsCard';

import { useModal } from 'context/ModalContext';

import image1 from 'assets/homepage/homepage_img1.png';
import image2 from 'assets/homepage/homepage_img2.png';
import image3 from 'assets/homepage/homepage_img3.png';

import { EVENT_LIST, MOCK_BRANDS } from '../../mockData';

const benefitsData = [
  {
    title: 'Brand',
    color: 'yellow',
    items: [
      'Search events',
      'Book places at events',
      'Create own Brand page',
      'Be searchable by others',
      'Be invited to events',
      'See Brand Analytics',
    ],
  },
  {
    title: 'Promoter',
    color: 'yellow',
    items: [
      'Create events',
      'Sell places at events',
      'Search brands',
      'Invite brands to events',
      'Manage live events',
      'See Promoter Analytics',
    ],
  },
  {
    title: 'None of the others',
    color: 'gray',
    items: [
      'Search events',
      'Search brands',
      'Buy entries for events',
      'Interact with brands',
    ],
  },
];

const Homepage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentPage = location.pathname;
  const [currentSearch, setCurrentSearch] = useState('events');

  const { showLoginModal } = useModal();

  const handleNavigate = (page) => {
    navigate(page);
  };

  const onSearch = (searchData) => {
    navigate(
      `/events?searchType=${currentSearch}&location=${searchData.selectedLocation}&categories=${searchData.selectedCategories.join(',')}`
    );
  };

  const renderSearchSection = () => {
    const tabs = ['Events', 'Brands', 'Stores', 'Pop-ups'];

    return (
      <div
        className="h-[540px] w-full bg-gray-100"
        style={{
          backgroundImage: `url(${image3})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="flex flex-col pl-40 justify-center items-start h-full">
          <h1 className="text-6xl text-yellow-02 mb-2">The best events</h1>
          <h1 className="text-6xl text-yellow-02 mb-20">For the best brands</h1>
          <h1 className="text-md text-gray-400 pl-2 mb-1">Search for:</h1>
          <TabGroup
            onChange={(index) => setCurrentSearch(tabs[index].toLowerCase())}
          >
            <TabList className="flex space-x-1 w-2/3 pl-2 mb-2">
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    `flex-1 text-center px-2 py-1 rounded-full outline-none ${
                      selected
                        ? tab.toLowerCase() === 'brands'
                          ? 'bg-black-01/80 text-white'
                          : 'bg-yellow-03/80 text-white'
                        : 'bg-transparent border border-gray-400/30 text-gray-400'
                    }`
                  }
                >
                  {tab}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabs.map((tab) => (
                <TabPanel key={tab}>
                  <EventSearchBar
                    onSearch={onSearch}
                    currentPage={currentPage}
                    currentSearch={tab.toLowerCase()}
                    onChangeSearch={setCurrentSearch}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    );
  };

  const renderEventList = () => {
    const events = EVENT_LIST.slice(0, 6);

    return (
      <div className="flex flex-col items-center justify-center py-8">
        <h1 className="text-yellow-04 text-5xl">Events near you</h1>
        <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3">
          {events.map((event) => (
            <div key={event.id} className="p-4">
              <EventCardWrapper event={event} />
            </div>
          ))}
        </div>
        <div className="bottom-0 left-0 right-0">
          <Link
            to="/events"
            className="container mx-auto px-4 flex justify-center"
          >
            <MapButton text="Show all events" />
          </Link>
        </div>
      </div>
    );
  };

  const renderGuestAction = () => {
    return (
      <div className="bg-yellow-03 flex flex-col items-center justify-center py-24">
        <div className=" flex flex-col space-y-2 justify-center items-center">
          <h2 className="text-5xl font-light text-white">
            Are you a <span className="font-bold">brand</span> or an
          </h2>
          <h2 className="text-5xl font-light text-white">
            event <span className="font-bold">promoter</span>?
          </h2>
        </div>
        <div className="mt-8">
          <button
            className="bg-gray-800 text-white px-10 py-4 rounded-full mx-2"
            onClick={showLoginModal}
          >
            Login
          </button>
          <button
            className="bg-gray-300 text-gray-800 px-6 py-4 rounded-full font-bold mx-2"
            onClick={() => handleNavigate('register')}
          >
            I'm new here!
          </button>
        </div>
      </div>
    );
  };

  const renderBrandCarousel = () => {
    return (
      <div className="bg-gray-100 py-8">
        <h1 className="text-4xl text-center text-gray-600 py-6">
          Brands which already joined us
        </h1>
        <div className="flex justify-center items-center mx-[300px] overflow-hidden">
          <BrandImageCarousel brands={MOCK_BRANDS} autoSlide={true} />
        </div>
      </div>
    );
  };

  const renderNumbersSection = () => {
    return (
      <div
        className="h-[540px] w-full py-8"
        style={{
          backgroundImage: `url(${image2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* <div className="flex flex-col justify-center items-start">
          <div className="flex flex-row mx-4">
            <h1 className="text-5xl text-yellow-02 font-bold">10k+</h1>
            <p className="text-5xl text-gray-100">Events</p>
          </div>
          <div className="flex flex-row mx-4">
            <h1 className="text-5xl text-yellow-02 font-bold">100+</h1>
            <p className="text-5xl text-gray-100">Events</p>
          </div>
          <div className="flex flex-row mx-4">
            <h1 className="text-5xl text-yellow-02 font-bold">1M+</h1>
            <p className="text-5xl text-gray-100">Brands</p>
          </div>
        </div> */}
      </div>
    );
  };

  const renderBenefitsSection = () => {
    return (
      <div className="bg-gray-100 py-16">
        <h2 className="text-center text-yellow-03 text-5xl mb-10">
          Why is <span className="text-yellow-03 font-bold">there2b</span> good
          for me?
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center md:space-y-0 md:space-x-8 px-20">
          {benefitsData.map((benefit, index) => (
            <BenefitsCard
              key={index}
              title={benefit.title}
              color={benefit.color}
              items={benefit.items}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderSquareSection = () => {
    return (
      <div className="bg-yellow-04 py-16 flex items-center justify-center px-20">
        <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">
          <div className="flex-shrink-0">
            <img src={image1} alt="Squares" className="object-cover" />
          </div>
          <div className="flex flex-col space-y-4 text-white text-4xl md:text-5xl items-end pb-36">
            <p>
              <span className="font-bold">Promoters</span> create{' '}
              <span className="font-bold">Events</span>.
            </p>
            <p>
              <span className="font-bold">Events</span> include{' '}
              <span className="font-bold">Places</span>.
            </p>
            <p>
              <span className="font-bold">Places</span> need{' '}
              <span className="font-bold">Brands</span>.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderSearchSection()}
      {renderEventList()}
      {renderGuestAction()}
      {renderBrandCarousel()}
      {renderNumbersSection()}
      {renderBenefitsSection()}
      {renderSquareSection()}
    </div>
  );
};

export default Homepage;
