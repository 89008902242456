// Create a new file for context, e.g., ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const showLoginModal = () => setLoginModalOpen(true);
  const hideLoginModal = () => setLoginModalOpen(false);

  return (
    <ModalContext.Provider
      value={{ isLoginModalOpen, showLoginModal, hideLoginModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
