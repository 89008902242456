import {
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  LIST_BRANDS_REQUEST,
  LIST_BRANDS_SUCCESS,
  LIST_BRANDS_FAIL,
} from '../actions/brand';

const initialState = {
  brand: null,
  brands: [],
  loading: false,
  error: null,
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
        loading: false,
        error: null,
      };
    case GET_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LIST_BRANDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload,
        loading: false,
        error: null,
      };
    case LIST_BRANDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default brandReducer;
