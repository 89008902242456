import React, { useState, useCallback, useRef, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from 'react-slider';
import { getCroppedImg } from 'utils/cropUtils';

const ImageCropper = ({ imageSrc, aspect = 1, onCropSave, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropContainerRef = useRef(null);

  const [containerSize, setContainerSize] = useState({
    width: 256,
    height: 256,
  });

  useEffect(() => {
    const updateContainerSize = () => {
      if (cropContainerRef.current) {
        setContainerSize({
          width: cropContainerRef.current.offsetWidth,
          height: cropContainerRef.current.offsetHeight,
        });
      }
    };

    // Update on mount and window resize
    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);

    return () => window.removeEventListener('resize', updateContainerSize);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      onCropSave(croppedImageBlob);
    } catch (error) {
      console.error('Error during cropping:', error);
    }
  }, [croppedAreaPixels, imageSrc, onCropSave]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg">
        <div ref={cropContainerRef} className="w-64 h-64 relative">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            showGrid={false}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            aspect={aspect}
            cropShape="round"
            cropSize={{
              width: containerSize.width - 10,
              height: containerSize.height - 10,
            }}
            style={{ containerStyle: { height: 'auto', width: '100%' } }}
          />
        </div>

        {/* Slider */}
        <div className="mt-4 pb-4">
          <Slider
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={setZoom}
            className="w-full"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
          />
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            type="button"
            className="bg-gray-300 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-yellow-500 text-white px-4 py-2 rounded-md"
            onClick={handleCropSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
