import axios from 'axios';

const geocodeLocation = async (location) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location)}&key=${API_KEY}`;

  try {
    const response = await axios.get(url);
    const { results } = response.data;

    if (results && results.length > 0) {
      const { lat, lng } = results[0].geometry.location;
      return { lat, lng };
    } else {
      console.error('No results found for location:', location);
      return null;
    }
  } catch (error) {
    console.error('Error fetching geocode:', error);
    return null;
  }
};

export default geocodeLocation;
