import React, { useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stage, Layer, Circle, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image'; // Hook to load images into Konva

const Step4 = () => {
  const { register, setValue } = useFormContext();
  const [floorPlanImage, setFloorPlanImage] = useState(null); // For the uploaded image
  console.log('🚀🚀 ~  Step4 ~  floorPlanImage:', floorPlanImage);
  const [spaces, setSpaces] = useState([]); // For storing space data
  const stageRef = useRef(null);

  // Load the floor plan image into Konva using useImage hook
  const [image, status] = useImage(
    floorPlanImage ? URL.createObjectURL(floorPlanImage) : null
  );

  // Handle space placement on the canvas
  const handleAddSpace = (e) => {
    const stage = stageRef.current.getStage();
    const point = stage.getPointerPosition();
    setSpaces([
      ...spaces,
      {
        x: point.x,
        y: point.y,
        status: 'available', // Default status
        id: spaces.length, // Unique ID for each space
      },
    ]);
  };

  // Update space status
  const updateSpaceStatus = (index, status) => {
    const updatedSpaces = spaces.map((space, i) => {
      if (i === index) {
        return { ...space, status };
      }
      return space;
    });
    setSpaces(updatedSpaces);
  };

  // Handle image upload for the blueprint
  const handleImageUpload = (e) => {
    setFloorPlanImage(e.target.files[0]);
    setValue('floorplanImage', e.target.files[0]); // Save to form state
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Floorplan</h2>

      <div className="mb-4">
        <label className="block">Upload Floorplan Image</label>
        <input
          type="file"
          onChange={handleImageUpload}
          className="border p-2 w-full"
        />
      </div>

      {floorPlanImage && (
        <div className="relative">
          <Stage
            ref={stageRef}
            width={800}
            height={600}
            onClick={handleAddSpace} // Add space when clicking the stage
          >
            <Layer>
              {/* Only render the image when the status is 'loaded' */}
              {status === 'loaded' ? (
                <KonvaImage image={image} width={800} height={600} />
              ) : status === 'loading' ? (
                <p>Loading Image...</p>
              ) : status === 'failed' ? (
                <p>Failed to load image</p>
              ) : null}

              {/* Render spaces (circles) */}
              {spaces.map((space, index) => (
                <Circle
                  key={space.id}
                  x={space.x}
                  y={space.y}
                  radius={20}
                  fill={
                    space.status === 'rented'
                      ? 'red'
                      : space.status === 'in-process'
                        ? 'yellow'
                        : 'green'
                  }
                  draggable // Let the user drag the circle
                  onClick={() => {
                    const newStatus = prompt(
                      'Update Status (available, in-process, rented):',
                      space.status
                    );
                    if (newStatus) {
                      updateSpaceStatus(index, newStatus);
                    }
                  }}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      )}
    </div>
  );
};

export default Step4;
