import React from 'react';
import CircleSolution from 'components/InteractiveMap/CircleSolution';

const InteractiveMap1 = () => {
  const venuePlanURL =
    'https://images.adsttc.com/media/images/5c08/1987/08a5/e5ce/eb00/00aa/newsletter/06_-_KOKO_architects_-_KOKO_architects_BSM_ground_floor.jpg?1544034687';

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-4">Event Creation Step</h1>
      <CircleSolution imageUrl={venuePlanURL} />
    </div>
  );
};

export default InteractiveMap1;
