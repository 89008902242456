import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { promoterSchema } from 'utils/validationSchema';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // For redirection
import { useModal } from '../../../context/ModalContext'; // Assuming similar to BrandForm
import { ClipLoader } from 'react-spinners'; // For the loading spinner
import { register } from '../../../redux/actions/auth.js';
import {
  UserIcon,
  GlobeAltIcon,
  LockClosedIcon,
  PhoneIcon,
  EnvelopeIcon,
  CameraIcon,
  LinkIcon,
  IdentificationIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid';

import ImageCropper from 'components/ImageCropper';
import { useImageCropper } from 'hooks/useImageCropper'; // Import the custom hook

export const PromoterForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate(); // To redirect after registration
  const { showLoginModal } = useModal(); // Assuming similar to BrandForm

  // Using the custom useImageCropper hook
  const {
    imagePreview,
    croppedImage: croppedAvatar,
    isCropping,
    handleImageChange,
    handleCropSave,
    setIsCropping,
  } = useImageCropper();

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(promoterSchema),
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (key === 'avatar') {
        formData.append(key, croppedAvatar); // Use the cropped avatar
      } else {
        formData.append(key, value);
      }
    });

    try {
      await dispatch(register('promoters', formData));
      navigate('/'); // Redirect to home page after successful registration
      showLoginModal(); // Open the login modal
    } catch (error) {
      console.error('Error submitting promoter registration:', error);
    } finally {
      setIsSubmitting(false); // Stop spinner
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-8 py-10 bg-white shadow-md rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="flex flex-row space-y-1 justify-between items-center">
          <div className="flex flex-col w-1/2 space-y-1">
            {/* Username */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <UserIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('username')}
                  placeholder="Enter your username"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.username && (
                <p className="text-red-500 text-sm">
                  {errors.username.message}
                </p>
              )}
            </div>

            {/* Company Name */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <UserIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('companyName')}
                  placeholder="Enter your company name"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full"
                />
              </div>
              {errors.companyName && (
                <p className="text-red-500 text-sm">
                  {errors.companyName.message}
                </p>
              )}
            </div>

            {/* Email */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <EnvelopeIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  {...formRegister('email')}
                  placeholder="Enter your email"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>

            {/* Password */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <LockClosedIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  type="password"
                  {...formRegister('password')}
                  placeholder="Enter your password"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>

            {/* Confirm Password */}
            <div className="flex flex-col space-y-1">
              <div className="relative">
                <LockClosedIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
                <input
                  type="password"
                  {...formRegister('confirmPassword')}
                  placeholder="Confirm your password"
                  className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
                />
              </div>
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
          </div>

          {/* Avatar Upload */}
          <div className="flex flex-col space-y-1 w-1/4 items-center">
            <label className="text-gray-700 font-medium">Upload Avatar</label>
            <div className="relative w-24 h-24">
              <input
                type="file"
                accept="image/*"
                id="avatar-upload"
                className="sr-only"
                onChange={handleImageChange} // Use the hook handler
              />
              <label
                htmlFor="avatar-upload"
                className="cursor-pointer relative"
              >
                {croppedAvatar ? (
                  <div className="relative">
                    <img
                      src={croppedAvatar}
                      alt="Avatar Preview"
                      className="w-24 h-24 rounded-full object-cover"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setIsCropping(false);
                        document.getElementById('avatar-upload').value = ''; // Clear file input
                      }}
                      className="absolute top-0 right-0 bg-white rounded-full p-1 shadow hover:bg-gray-100"
                    >
                      <XCircleIcon className="w-6 h-6 text-red-500" />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
                    <CameraIcon className="w-10 h-10 text-gray-500" />
                  </div>
                )}
              </label>
            </div>
            {errors.avatar && (
              <p className="text-red-500 text-sm">{errors.avatar.message}</p>
            )}
          </div>

          {/* Image Cropper Modal */}
          {isCropping && imagePreview && (
            <ImageCropper
              imageSrc={imagePreview}
              aspect={1}
              onCropSave={handleCropSave}
              onClose={() => setIsCropping(false)}
            />
          )}
        </div>

        {/* Profile Info */}
        <div className="flex flex-col space-y-1">
          <textarea
            {...formRegister('profileInfo')}
            placeholder="Enter a profile bio"
            className="border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
          />
          {errors.profileInfo && (
            <p className="text-red-500 text-sm">{errors.profileInfo.message}</p>
          )}
        </div>

        {/* Country */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <GlobeAltIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('country')}
              placeholder="Enter your country"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country.message}</p>
          )}
        </div>

        {/* City */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <GlobeAltIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('city')}
              placeholder="Enter your city"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.city && (
            <p className="text-red-500 text-sm">{errors.city.message}</p>
          )}
        </div>

        {/* Fiscal Number */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <IdentificationIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('fiscalNumber')}
              placeholder="Enter your fiscal number"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.fiscalNumber && (
            <p className="text-red-500 text-sm">
              {errors.fiscalNumber.message}
            </p>
          )}
        </div>

        {/* Phone Number */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <PhoneIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('phoneNumber')}
              placeholder="Enter your phone number"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.phoneNumber && (
            <p className="text-red-500 text-sm">{errors.phoneNumber.message}</p>
          )}
        </div>

        {/* Website URL */}
        <div className="flex flex-col space-y-1">
          <div className="relative">
            <LinkIcon className="absolute left-3 top-3 w-6 h-6 text-gray-400" />
            <input
              {...formRegister('websiteUrl')}
              placeholder="Enter your website URL"
              className="pl-12 border-gray-300 rounded-md shadow-sm p-3 w-full outline-none"
            />
          </div>
          {errors.websiteUrl && (
            <p className="text-red-500 text-sm">{errors.websiteUrl.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-center pt-10">
          <button
            type="submit"
            className="bg-yellow-500 text-white py-3 px-12 rounded-full text-lg transition-all duration-300 hover:bg-yellow-600 flex items-center justify-center font-bold"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ClipLoader color="#FFFFFF" size={24} />
            ) : (
              'Confirm Registration'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PromoterForm;
