import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import {
  XCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';

const toastTypes = {
  success: {
    icon: CheckCircleIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800',
  },
  error: {
    icon: ExclamationCircleIcon,
    bgColor: 'bg-red-100',
    textColor: 'text-red-800',
  },
  neutral: {
    icon: InformationCircleIcon,
    bgColor: 'bg-yellow-03/80',
    textColor: 'text-white',
  },
};

const Toast = ({ message, type, onClose }) => {
  const {
    icon: Icon,
    bgColor,
    textColor,
  } = toastTypes[type] || toastTypes.neutral;

  return (
    <Transition
      as="div"
      appear
      show={true}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`fixed bottom-4 right-4 p-4 flex items-center space-x-4 shadow-lg rounded-lg ${bgColor}`}
    >
      <Icon className={`h-6 w-6 ${textColor}`} />
      <span className={`text-sm font-medium ${textColor}`}>{message}</span>
      <button onClick={onClose} className={`${textColor} hover:text-gray-600`}>
        <XCircleIcon className="h-5 w-5 bg-transparent" />
      </button>
    </Transition>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'neutral']),
  onClose: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  type: 'neutral',
};

export default Toast;
