import React from 'react';
import logo from './6.svg';

const Footer = () => {
  return (
    <footer className="w-full bg-yellow-03 p-10 text-white">
      <div className="max-w-6xl mx-auto flex flex-row">
        <div className="w-1/4">
          <img src={logo} alt="Logo" className="w-40 h-40 mb-2" />
        </div>
        <div className="flex w-3/4 space-x-20">
          <div className="flex flex-col">
            <h2 className="font-bold text-lg mb-2">About t2b</h2>
            <a href="/history" className="mb-1">
              History
            </a>
            <a href="/team" className="mb-1">
              Our team
            </a>
            <a href="/careers" className="mb-1">
              Careers
            </a>
            <a href="/company" className="mb-1">
              Company
            </a>
            <a href="/press" className="mb-1">
              Press
            </a>
          </div>
          <div className="flex flex-col">
            <h2 className="font-bold mb-2">Support</h2>
            <a href="/contacts" className="mb-1">
              Contacts
            </a>
            <a href="/faqs" className="mb-1">
              FAQs
            </a>
          </div>
          <div className="flex flex-col">
            <h2 className="font-bold mb-2">How it works</h2>
            <a href="/event-promoter" className="mb-1">
              I'm an event promoter
            </a>
            <a href="/brand" className="mb-1">
              I'm a brand
            </a>
            <a href="/find-place" className="mb-1">
              Find a place
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
