import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { useState } from 'react';

const MOCK_PLANS = [
  {
    name: 'Plano Mini',
    ram: '2,25 m2',
    cpus: '400,00€',
    disk: 'IVA não incluido',
  },
  {
    name: 'Plano Base',
    ram: '6 m2',
    cpus: '500,00€',
    disk: 'IVA não incluido',
  },
  {
    name: 'Plano Maxi',
    ram: '9 m2',
    cpus: '650,00€',
    disk: 'IVA não incluido',
  },
];

const RadioGroupComponent = () => {
  const [selected, setSelected] = useState(MOCK_PLANS[0]);

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className='sr-only'>Server size</RadioGroup.Label>
      <div className='space-y-2'>
        {MOCK_PLANS.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ active, checked }) =>
              `relative block border ${
                checked ? 'border-transparent' : 'border-gray-300'
              } bg-white p-4 shadow-sm focus:outline-none ${
                active ? 'ring-2 ring-blue-500' : ''
              }`
            }
          >
            {({ checked }) => (
              <>
                <RadioGroup.Label
                  as='p'
                  className='text-sm font-medium text-gray-900'
                >
                  {plan.name}
                </RadioGroup.Label>
                <RadioGroup.Description as='div' className='text-sm'>
                  <p className='text-gray-500'>{plan.ram}</p>
                  <p className='text-gray-500'>{plan.cpus}</p>
                  <p className='text-gray-500'>{plan.disk}</p>
                </RadioGroup.Description>
                {checked && (
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3'>
                    {/* Checked icon */}
                  </div>
                )}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupComponent;
