import {
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_PROMOTER_EVENTS_REQUEST,
  GET_PROMOTER_EVENTS_SUCCESS,
  GET_PROMOTER_EVENTS_FAIL,
} from '../actions/events';

const initialState = {
  events: [],
  eventDetails: {
    event: null,
    loading: false,
    error: null,
  },
  error: null,
  loading: false,
};
// Reducer
const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVENTS_REQUEST:
    case GET_PROMOTER_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EVENTS_SUCCESS:
    case GET_PROMOTER_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loading: false,
        error: null,
      };
    case GET_EVENTS_FAIL:
    case GET_PROMOTER_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        eventDetails: {
          event: action.payload,
          loading: false,
          error: null,
        },
      };
    case GET_EVENT_FAIL:
      return {
        ...state,
        eventDetails: {
          event: null,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default eventReducer;
