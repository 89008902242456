import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import PropTypes from 'prop-types';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useModal } from '../../context/ModalContext';
import { useToast } from '../../context/toastContext';

import ProfileButton from 'components/ProfileButton';

import logo from './nome.svg';

const Header = ({ sticky = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToast = useToast();
  const { showLoginModal } = useModal();

  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const handleLogout = () => {
    dispatch(logout());
    addToast('Logged out successfully', 'success');
  };

  const notificationCount = useSelector(
    (state) => state.notifications.unReadCount
  );

  const avatarUrl =
    user?.avatarUrl ||
    'https://thumbs.dreamstime.com/b/design-vetorial-de-logotipo-preto-nike-sport-pronto-para-imprimir-arte-183282273.jpg';

  const renderLoginButton = () => {
    return (
      <button
        type="button"
        onClick={showLoginModal}
        className="text-yellow-03 border border-yellow-03 hover:bg-yellow-03 hover:text-white font-bold py-2 px-4 rounded"
      >
        Sign in
      </button>
    );
  };

  const renderRegisterButton = () => {
    return (
      <button
        type="button"
        onClick={() => navigate('/register')}
        className="bg-yellow-03  text-white font-bold py-2 px-4 rounded"
      >
        Sign Up
      </button>
    );
  };

  const handleNavigateToHome = () => {
    navigate('/');
  };

  return (
    <nav
      className={`bg-gray-100 p-4 flex justify-between items-center flex-nowrap ${sticky ? 'sticky top-0 z-20' : ''}`}
    >
      <div className="flex items-center space-x-8">
        <div className="flex items-center space-x-4">
          <div
            className="text-yellow-03 text-2xl font-bold cursor-pointer"
            onClick={handleNavigateToHome}
          >
            <img src={logo} alt="There2Be" className="w-64 ml-8" />
          </div>
          <Menu as="div" className="md:hidden">
            <MenuButton className="text-white">
              <Bars3Icon className="h-6 w-6" />
            </MenuButton>
            <MenuItems className="absolute right-0 w-48 mt-2 origin-top-right bg-yellow-500 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem>
                {({ active }) => (
                  <a
                    href="/events"
                    className={`block px-4 py-2 text-sm text-white ${active ? 'bg-yellow-600' : ''}`}
                  >
                    Events
                  </a>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <a
                    href="/Showroom"
                    className={`block px-4 py-2 text-sm text-white ${active ? 'bg-yellow-600' : ''}`}
                  >
                    Showroom
                  </a>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>

        <div className="hidden md:flex items-center space-x-4">
          <a
            href="/events"
            className="text-gray-400 text-xl hover:text-gray-600"
          >
            Events
          </a>
          <a
            href="/brands"
            className="text-gray-400 text-xl hover:text-gray-600"
          >
            Brands
          </a>
          <a href="/" className="text-gray-400 text-xl hover:text-gray-600">
            Places
          </a>
          <a href="/" className="text-gray-400 text-xl hover:text-gray-600">
            About
          </a>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {isAuthenticated ? (
          <ProfileButton
            avatarUrl={avatarUrl}
            notificationCount={notificationCount}
            onLogout={handleLogout}
          />
        ) : (
          <div className="flex items-center space-x-4">
            {renderLoginButton()}
            {renderRegisterButton()}
          </div>
        )}
        <div
          className="text-gray-400"
          onClick={() => console.log('change language')}
        >
          PT
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  sticky: PropTypes.bool,
};

export default Header;
