import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ModalProvider } from './context/ModalContext';
import { ToastProvider } from './context/toastContext';
import { Provider } from 'react-redux';
import store from './redux/store';

import ListingPage from './pages/ListingPage';
import EventDetail from './pages/EventDetail';
import ComponentShowroom from './pages/Showroom';
import EventListMap from './pages/EventListMap';
import Register from './pages/Register';
import InteractiveMap1 from './pages/InteractiveMap1';
import Dashboard from './pages/Dashboard';
import EventCreation from './pages/EventCreation';
import Homepage from './pages/Homepage';
import BrandDetail from './pages/BrandDetail';
import Payments from './pages/Payments';

import Layout from './components/Layout';
import LoginModal from './components/LoginModal';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const PASSWORD = process.env.REACT_APP_WEBSITE_ACCESS_PASSWORD;

  const handleLogin = () => {
    if (inputPassword === PASSWORD) {
      setAuthenticated(true);
    } else {
      alert('Invalid password! Please try again.');
    }
  };

  if (!authenticated) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h1>Restricted Access</h1>
        <input
          type="password"
          placeholder="Enter Password"
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
          style={{ marginBottom: '10px', padding: '8px', fontSize: '16px' }}
        />
        <button
          onClick={handleLogin}
          style={{ padding: '8px 16px', fontSize: '16px' }}
        >
          Login
        </button>
      </div>
    );
  }

  // Render the full app after successful authentication
  return (
    <ToastProvider>
      <ModalProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout stickyHeader={true}>
                  <Homepage />
                </Layout>
              }
            />
            <Route
              path="/events"
              element={
                <Layout stickyHeader={true}>
                  <ListingPage />
                </Layout>
              }
            />
            <Route
              path="/interactive-map1"
              element={
                <Layout>
                  <InteractiveMap1 />
                </Layout>
              }
            />
            <Route
              path="/event/:eventId"
              element={
                <Layout stickyHeader={true}>
                  <EventDetail />
                </Layout>
              }
            />
            <Route
              path="/events/map"
              element={
                <Layout stickyHeader={true}>
                  <EventListMap />
                </Layout>
              }
            />
            <Route
              path="/showroom"
              element={
                <Layout stickyHeader={true}>
                  <ComponentShowroom />
                </Layout>
              }
            />
            <Route
              path="/register"
              element={
                <Layout stickyHeader={true}>
                  <Register />
                </Layout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Layout stickyHeader={true}>
                    <Dashboard />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/create-event"
              element={
                <PrivateRoute>
                  <Layout stickyHeader={true} noFooter>
                    <EventCreation />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/brands/:brandId"
              element={
                <Layout stickyHeader={true}>
                  <BrandDetail />
                </Layout>
              }
            />
            <Route
              path="/payments"
              element={
                <Layout stickyHeader={true}>
                  <Payments />
                </Layout>
              }
            />
          </Routes>
          <LoginModal />
        </Router>
      </ModalProvider>
    </ToastProvider>
  );
}

const RootApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default RootApp;
