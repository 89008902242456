import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SpaceCard from 'components/SpaceCard'; // Adjust the import path as necessary

const SpaceCardCarousel = ({ spaces }) => {
  const [chunkIndex, setChunkIndex] = useState(0);
  const cardsPerChunk = 3;

  const numberOfChunks = Math.ceil(spaces.length / cardsPerChunk);

  const nextChunk = () => {
    setChunkIndex((prevIndex) => Math.min(prevIndex + 1, numberOfChunks - 1));
  };

  const prevChunk = () => {
    setChunkIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const visibleSpaces = spaces.slice(
    chunkIndex * cardsPerChunk,
    (chunkIndex + 1) * cardsPerChunk
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-end mb-2">
        <button
          onClick={prevChunk}
          className={`px-4 py-2 justify-center items-center rounded-3xl text-xl ml-2 ${chunkIndex === 0 ? 'bg-gray-200 text-gray-400' : 'bg-white'}`}
          disabled={chunkIndex === 0}
        >
          {'<'}
        </button>
        <button
          onClick={nextChunk}
          className={`px-4 py-2 justify-center items-center rounded-3xl text-xl ml-2 ${chunkIndex === numberOfChunks - 1 ? 'bg-gray-200 text-gray-400' : 'bg-white'}`}
          disabled={chunkIndex === numberOfChunks - 1}
        >
          {'>'}
        </button>
      </div>
      <div className="flex gap-10">
        {visibleSpaces.map((space) => (
          <SpaceCard key={space.id} space={space} />
        ))}
      </div>
    </div>
  );
};

SpaceCardCarousel.propTypes = {
  spaces: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SpaceCardCarousel;
