import axios from '../../utils/axiosConfig';

// Action Types
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS';
export const CREATE_SPACE_FAIL = 'CREATE_SPACE_FAIL';
export const CREATE_SPACES_SUCCESS = 'CREATE_SPACES_SUCCESS';
export const CREATE_SPACES_FAIL = 'CREATE_SPACES_FAIL';
export const GET_SPACES_REQUEST = 'GET_SPACES_REQUEST';
export const GET_SPACES_SUCCESS = 'GET_SPACES_SUCCESS';
export const GET_SPACES_FAIL = 'GET_SPACES_FAIL';
export const GET_SPACE_SUCCESS = 'GET_SPACE_SUCCESS';
export const GET_SPACE_FAIL = 'GET_SPACE_FAIL';
export const UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS';
export const UPDATE_SPACE_FAIL = 'UPDATE_SPACE_FAIL';
export const DELETE_SPACE_SUCCESS = 'DELETE_SPACE_SUCCESS';
export const DELETE_SPACE_FAIL = 'DELETE_SPACE_FAIL';

// Action Creators

// Create a new space
export const createSpace = (spaceData) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.post('/spaces', spaceData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_SPACE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_SPACE_FAIL,
      payload: err.response?.data?.message || 'Error creating space',
    });
  }
};

// Create Bulk spaces
export const createSpaces = (spacesData) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.post('/spaces/bulk', spacesData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_SPACES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: CREATE_SPACES_FAIL,
      payload: err.response?.data?.message || 'Error creating spaces',
    });
    throw new Error(err.response?.data?.message || 'Error creating spaces');
  }
};

// Get all spaces for a specific event
export const getSpacesForEvent = (eventId) => async (dispatch, getState) => {
  dispatch({ type: GET_SPACES_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get(`/spaces/event/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SPACES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SPACES_FAIL,
      payload: err.response?.data?.message || 'Error fetching spaces',
    });
  }
};

// Get a single space by its ID
export const getSpace = (spaceId) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get(`/spaces/${spaceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SPACE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SPACE_FAIL,
      payload: err.response?.data?.message || 'Error fetching space',
    });
  }
};

// Update a space
export const updateSpace =
  (spaceId, spaceData) => async (dispatch, getState) => {
    const { auth } = getState();
    const token = auth.token;
    try {
      const res = await axios.put(`/spaces/${spaceId}`, spaceData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: UPDATE_SPACE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_SPACE_FAIL,
        payload: err.response?.data?.message || 'Error updating space',
      });
    }
  };

// Delete a space
export const deleteSpace = (spaceId) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    await axios.delete(`/spaces/${spaceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_SPACE_SUCCESS,
      payload: spaceId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_SPACE_FAIL,
      payload: err.response?.data?.message || 'Error deleting space',
    });
  }
};
