import axios from '../../utils/axiosConfig';

// Action Types
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';

export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const GET_PROMOTER_EVENTS_REQUEST = 'GET_PROMOTER_EVENTS_REQUEST';
export const GET_PROMOTER_EVENTS_SUCCESS = 'GET_PROMOTER_EVENTS_SUCCESS';
export const GET_PROMOTER_EVENTS_FAIL = 'GET_PROMOTER_EVENTS_FAIL';

// Actions
export const createEvent = (eventData) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.post('/events', eventData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_EVENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_EVENT_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const getAllEvents = () => async (dispatch, getState) => {
  dispatch({ type: GET_EVENTS_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get('/events', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_EVENTS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EVENTS_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const getEvent = (eventId) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    console.log('Fetching event from backend with ID:', eventId); // Debug
    const res = await axios.get(`/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('Fetched event:', res.data); // Debug
    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error('Failed to fetch event:', err.response?.data); // Debug
    dispatch({
      type: GET_EVENT_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch event details',
    });
  }
};

export const getPromoterEvents = () => async (dispatch, getState) => {
  dispatch({ type: GET_PROMOTER_EVENTS_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get('/events/promoter-events', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_PROMOTER_EVENTS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTER_EVENTS_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch promoter events',
    });
  }
};

export const updateEvent =
  (eventId, eventData) => async (dispatch, getState) => {
    const { auth } = getState();
    const token = auth.token;
    try {
      const res = await axios.put(`/events/${eventId}`, eventData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: UPDATE_EVENT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_EVENT_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteEvent = (eventId) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;
  try {
    await axios.delete(`/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_EVENT_SUCCESS,
      payload: eventId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_EVENT_FAIL,
      payload: err.response.data.message,
    });
  }
};
