import React from 'react';

const Preferences = () => {
  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      <div className="flex flex-col p-10 justify-between">
        <div>
          <h3 className="pt-2 text-sm font-bold text-yellow-02">Language</h3>
          <p className="text-lg font-bold">English</p>
        </div>
        <div>
          <h3 className="pt-2 text-sm font-bold text-yellow-02">Currency</h3>
          <p className="text-lg font-bold">€ - Euro</p>
        </div>
        <div>
          <h3 className="pt-2 text-sm font-bold text-yellow-02">Time zone</h3>
          <p className="text-lg font-bold">(GMT +00:00) Lisbon</p>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
