import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabGroup, TabPanel, TabPanels, TabList } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import floorImage from 'assets/images/floorplan.png';

import SpaceRowList from 'components/SpaceRowList';

// Sample data for the circles
const places = [
  { x: 200, y: 300, name: 'Extra Large', price: '600€', size: '9m²' },
  { x: 300, y: 300, name: 'Large', price: '450€', size: '6m²' },
  { x: 100, y: 300, name: 'Extra Large', price: '600€', size: '9m²' },
  { x: 300, y: 100, name: 'Large', price: '450€', size: '6m²' },
  { x: 100, y: 100, name: 'Small', price: '600€', size: '9m²' },
  { x: 100, y: 100, name: 'Small', price: '450€', size: '6m²' },
  // Add more places as needed
];

const SpaceModal = ({
  spaces,
  active,
  onClose,
  setOption,
  option,
  onPlaceChange,
}) => {
  const [hoveredPlace, setHoveredPlace] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const getTabIndex = (option) => {
    const tabMap = {
      plan: 0,
      floorplan: 1,
    };
    return tabMap[option] || 0;
  };

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [active]);

  const handleCircleClick = (place) => {
    setSelectedPlace(place);
  };

  const handleRowClick = (place) => {
    setSelectedPlace(place);
  };

  const handleTabChange = (option) => {
    setOption(option);
  };

  const handleConfirmAction = () => {
    onPlaceChange(selectedPlace);
    onClose();
  };

  if (!active) return null;

  return (
    <div className="fixed inset-0 z-30 flex items-center justify-center py-8">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        onClick={onClose}
      ></div>
      <div className="relative w-full max-h-full overflow-hidden bg-gray-100 rounded-3xl p-12 text-center align-middle shadow-2xl transform transition-all sm:max-w-xl md:max-w-2xl lg:max-w-4xl">
        <button
          type="button"
          className="absolute top-0 right-0 m-2 text-yellow-02 hover:text-yellow-03 focus:outline-none"
          onClick={onClose}
        >
          <XMarkIcon className="h-10 w-10" aria-hidden="true" />
        </button>
        <TabGroup defaultIndex={getTabIndex(option)} onChange={handleTabChange}>
          <TabList className="flex p-1 space-x-1 bg-gray-200 rounded-full">
            <Tab
              className={({ selected }) =>
                `w-full rounded-full py-2 text-md text-white font-medium leading-5 transition-colors duration-150 ${
                  selected ? 'bg-yellow-02 shadow font-bold' : ''
                }`
              }
            >
              Pick a plan
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full rounded-full py-2 text-md text-white font-medium leading-5 transition-colors duration-150 ${
                  selected ? 'bg-yellow-02 shadow font-bold' : ''
                }`
              }
            >
              Pick a Floorplan
            </Tab>
          </TabList>
          <TabPanels className="mt-1">
            <TabPanel className="rounded-full">
              <div className="max-h-[600px] overflow-y-auto">
                <SpaceRowList
                  spaces={spaces}
                  selectedPlace={selectedPlace}
                  onRowClick={handleRowClick}
                />
              </div>
            </TabPanel>
            <TabPanel className="rounded-xl p-3">
              <div className="relative h-[600px] overflow-auto">
                <svg width="1200" height="800" viewBox="0 0 1200 800">
                  <image href={floorImage} width="1200" height="800" />
                  {places.map((place, index) => (
                    <circle
                      key={index}
                      cx={place.x}
                      cy={place.y}
                      r="10"
                      fill={selectedPlace === place ? 'red' : 'green'}
                      onMouseEnter={() => setHoveredPlace(place)}
                      onMouseLeave={() => setHoveredPlace(null)}
                      onClick={() => handleCircleClick(place)}
                      className="cursor-pointer"
                    />
                  ))}
                </svg>
                {hoveredPlace && (
                  <div
                    className="absolute w-[150px] bg-white border border-gray-300 p-2 shadow-lg rounded-lg"
                    style={{ top: hoveredPlace.y, left: hoveredPlace.x }}
                  >
                    <p className="font-bold">{hoveredPlace.name}</p>
                    <p className="font-bold">{hoveredPlace.price}</p>
                    <p className="text-yellow-03">{hoveredPlace.size}</p>
                  </div>
                )}
              </div>
            </TabPanel>
          </TabPanels>
          <div className="absolute bottom-0 left-0 w-full rounded-3xl h-60 bg-gradient-to-t from-gray-200/80 z-10 to-transparent" />
          <div className="flex flex-row justify-center items-center">
            <button
              className={`px-10 py-4 text-2xl font-bold rounded-full z-20 text-white fixed bottom-0 mb-10 ${
                selectedPlace
                  ? 'bg-green-500'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              onClick={handleConfirmAction}
              disabled={!selectedPlace}
            >
              Confirm selection
            </button>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

SpaceModal.propTypes = {
  spaces: PropTypes.array,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  setOption: PropTypes.func,
  option: PropTypes.string,
  onPlaceChange: PropTypes.func,
};

export default SpaceModal;
