import React from 'react';
import PropTypes from 'prop-types';

import SpaceRow from 'components/SpaceRow';

const SpaceRowList = ({ spaces, selectedPlace, onRowClick }) => {
  return (
    <div className="flex flex-col h-full w-full pb-40">
      {spaces.map((item, index) => (
        <div key={index} onClick={() => onRowClick(item)}>
          <SpaceRow space={item} key={index} active={selectedPlace === item} />
        </div>
      ))}
    </div>
  );
};

SpaceRowList.propTypes = {
  spaces: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default SpaceRowList;
