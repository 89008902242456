import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stage, Layer, Circle, Image, Rect } from 'react-konva';
import { exportStageSVG } from 'react-konva-to-svg';
import { RadioGroup, Label, Description, Radio } from '@headlessui/react';
import { useImage } from 'hooks/useImage';
import { CheckIcon } from '@heroicons/react/24/outline';

import { MOCK_SPACES } from '../../../mockData';
import Toolbar from 'components/Toolbar';

const CircleSolution = ({ imageUrl, setFinalImage }) => {
  const [spaces, setSpaces] = useState([]);
  // const [finalImage, setFinalImage] = useState(null);

  const [undoStack, setUndoStack] = useState([]);
  const [selectedSpaceType, setSelectedSpaceType] = useState(MOCK_SPACES[0]);
  const [spaceTypes, setSpaceTypes] = useState(MOCK_SPACES.slice(0, 5)); // Initialized with MOCK_SPACES
  const [stageSize, setStageSize] = useState({ width: 600, height: 400 });
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  //test ready
  const [isReady, setIsReady] = useState(false);

  const [selectionRect, setSelectionRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    visible: false,
  });
  const stageRef = useRef(null);
  const [image] = useImage(imageUrl, 'Anonymous');
  const navigate = useNavigate();

  const calculateInitialSize = () => {
    // Width calculation remains the same
    const width =
      window.innerWidth > 768 ? window.innerWidth * (2 / 3) : window.innerWidth;

    // caps the height at 600px, but adjusts based on the viewport height
    const maxHeight = 600;
    const viewportHeightAdjustment =
      window.innerHeight > 768
        ? window.innerHeight * 0.75
        : window.innerHeight * 0.5;
    const height = Math.min(maxHeight, viewportHeightAdjustment);

    return { width, height };
  };

  const resetSpaces = () => {
    setSpaces([]);
    setSpaceTypes(MOCK_SPACES.slice(0, 5));
    setSelectedSpaceType(MOCK_SPACES[0]);
    setIsSelectionMode(false);
    setSelectedSpaces([]);
    setSelectionRect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      visible: false,
    });
    // take this off
    setFinalImage(null);
  };

  useEffect(() => {
    const updateSize = () => {
      setStageSize(calculateInitialSize());
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const addSpace = (e) => {
    if (selectedSpaceType.totalQuantity > 0) {
      const stage = stageRef.current;
      const { x, y } = stage.getPointerPosition();
      const newSpace = {
        id: Math.random().toString(36).substr(2, 9), // Simple unique ID, consider a more robust method for production
        x,
        y,
        type: selectedSpaceType.name,
      };

      setSpaces((currentSpaces) => [...currentSpaces, newSpace]);

      // Push the current state to the undoStack before adding the new space
      setUndoStack((currentSpaces) => [...currentSpaces, newSpace]);

      // Update totalQuantity for the selectedSpaceType
      const updatedSpaceTypes = spaceTypes.map((type) =>
        type.id === selectedSpaceType.id
          ? { ...type, totalQuantity: type.totalQuantity - 1 }
          : type
      );
      setSpaceTypes(updatedSpaceTypes);

      // Ensure selectedSpaceType is updated to maintain reference equality if needed
      const updatedSelectedSpaceType = updatedSpaceTypes.find(
        (type) => type.id === selectedSpaceType.id
      );
      setSelectedSpaceType(updatedSelectedSpaceType);
    }
  };

  const undoLastAction = () => {
    setUndoStack((currentUndoStack) => {
      if (currentUndoStack.length === 0) return currentUndoStack; // Early exit if no actions to undo

      const lastAction = currentUndoStack[currentUndoStack.length - 1];

      // Prepare for batched state updates
      const newUndoStack = currentUndoStack.slice(0, -1);
      const newSpaces = spaces.filter(
        (_, index) => index !== spaces.length - 1
      );
      const newSpaceTypes = spaceTypes.map((type) => {
        if (type.name === lastAction.type) {
          return { ...type, totalQuantity: type.totalQuantity + 1 };
        }
        return type;
      });

      // Batch state updates
      setSpaces(newSpaces);
      setSpaceTypes(newSpaceTypes);

      setSelectedSpaceType((currentSelectedSpaceType) => {
        if (currentSelectedSpaceType.name === lastAction.type) {
          return newSpaceTypes.find(
            (type) => type.name === currentSelectedSpaceType.name
          );
        }
        return currentSelectedSpaceType;
      });

      return newUndoStack;
    });
  };

  const calculateScale = (imgWidth, imgHeight, stageWidth, stageHeight) => {
    const scaleX = stageWidth / imgWidth;
    const scaleY = stageHeight / imgHeight;
    return Math.min(scaleX, scaleY); // Use the smallest scale factor to ensure the entire image fits within the stage
  };

  const imageScale = image
    ? calculateScale(
        image.width,
        image.height,
        stageSize.width,
        stageSize.height
      )
    : 1;

  const getRemainingSpacesByType = (typeName) => {
    const spaceType = spaceTypes.find((type) => type.name === typeName);
    return spaceType ? spaceType.totalQuantity : 0;
  };

  //Aligning functions

  const alignSelectedSpacesHorizontally = () => {
    if (selectedSpaces.length === 0) return;

    // Calculate the average 'y' position of all selected spaces
    const avgY =
      selectedSpaces.reduce((acc, cur) => acc + cur.y, 0) /
      selectedSpaces.length;

    // Update the 'y' position of all selected spaces to the average 'y'
    const updatedSpaces = spaces.map((space) =>
      selectedSpaces.find((selectedSpace) => selectedSpace.id === space.id)
        ? { ...space, y: avgY }
        : space
    );

    setSpaces(updatedSpaces);
  };

  const alignSelectedSpacesVertically = () => {
    if (selectedSpaces.length === 0) return;

    // Calculate the average 'x' position of all selected spaces
    const avgX =
      selectedSpaces.reduce((acc, cur) => acc + cur.x, 0) /
      selectedSpaces.length;

    // Update the 'x' position of all selected spaces to the average 'x'
    const updatedSpaces = spaces.map((space) =>
      selectedSpaces.find((selectedSpace) => selectedSpace.id === space.id)
        ? { ...space, x: avgX }
        : space
    );

    setSpaces(updatedSpaces);
  };

  const alignSelectedSpacesEvenlyVertically = () => {
    if (selectedSpaces.length < 2) return;

    // Sort selected spaces by 'y' position
    const sortedSpaces = [...selectedSpaces].sort((a, b) => a.y - b.y);
    const firstSpace = sortedSpaces[0];
    const lastSpace = sortedSpaces[sortedSpaces.length - 1];

    // Calculate the total distance and the spacing between spaces
    const totalDistance = lastSpace.y - firstSpace.y;
    const spacing = totalDistance / (sortedSpaces.length - 1);

    // Update positions
    const updatedSpaces = spaces.map((space) => {
      const selectedIndex = sortedSpaces.findIndex(
        (selectedSpace) => selectedSpace.id === space.id
      );
      if (selectedIndex !== -1) {
        return { ...space, y: firstSpace.y + spacing * selectedIndex };
      }
      return space;
    });

    setSpaces(updatedSpaces);
  };

  const alignSelectedSpacesEvenlyHorizontally = () => {
    // at least two spaces to align
    if (selectedSpaces.length < 2) return;

    // Sort selected spaces by 'x' position
    const sortedSpaces = [...selectedSpaces].sort((a, b) => a.x - b.x);
    const firstSpace = sortedSpaces[0];
    const lastSpace = sortedSpaces[sortedSpaces.length - 1];

    // Calculate the total distance and the spacing between spaces
    const totalDistance = lastSpace.x - firstSpace.x;
    const spacing = totalDistance / (sortedSpaces.length - 1);

    // Update positions
    const updatedSpaces = spaces.map((space) => {
      const selectedIndex = sortedSpaces.findIndex(
        (selectedSpace) => selectedSpace.id === space.id
      );
      if (selectedIndex !== -1) {
        return { ...space, x: firstSpace.x + spacing * selectedIndex };
      }
      return space;
    });

    setSpaces(updatedSpaces);
  };

  //Selecting Logic

  const toggleSelectionMode = () => {
    if (isSelectionMode) {
      setSelectedSpaces([]);
      setSelectionRect({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        visible: false,
      });
    }
    setIsSelectionMode(!isSelectionMode);
  };
  const onMouseDown = (e) => {
    // Exit early if not in selection mode and it's not a space click
    if (!isSelectionMode && e.target === e.target.getStage()) {
      return;
    }

    const clickedSpace = spaces.find((space) => space.id === e.target.id());

    if (isSelectionMode && clickedSpace) {
      // Direct click on a space in selection mode, toggle its selection
      const isAlreadySelected = selectedSpaces.some(
        (selectedSpace) => selectedSpace.id === clickedSpace.id
      );
      if (isAlreadySelected) {
        setSelectedSpaces(
          selectedSpaces.filter((space) => space.id !== clickedSpace.id)
        );
      } else {
        setSelectedSpaces([...selectedSpaces, clickedSpace]);
      }
    } else if (isSelectionMode) {
      // Clicked on the stage to start a selection rectangle
      const pos = e.target.getStage().getPointerPosition();
      setSelectionRect({
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0,
        visible: true,
      });
      setIsSelecting(true);
    }
  };

  const onMouseMove = (e) => {
    if (!isSelecting) return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    setSelectionRect((currentRect) => {
      const newWidth = point.x - currentRect.x;
      const newHeight = point.y - currentRect.y;
      return { ...currentRect, width: newWidth, height: newHeight };
    });
  };

  const onMouseUp = () => {
    setIsSelecting(false);
    // Normalize the rectangle coordinates
    const rectStartX = selectionRect.x;
    const rectEndX = selectionRect.x + selectionRect.width;
    const rectStartY = selectionRect.y;
    const rectEndY = selectionRect.y + selectionRect.height;

    const [minX, maxX] = [
      Math.min(rectStartX, rectEndX),
      Math.max(rectStartX, rectEndX),
    ];
    const [minY, maxY] = [
      Math.min(rectStartY, rectEndY),
      Math.max(rectStartY, rectEndY),
    ];

    // Filter spaces to find which are inside the normalized rectangle bounds
    const selected = spaces.filter((space) => {
      // Assuming 'space.x' and 'space.y' refer to the center point of the space
      return (
        space.x >= minX && space.x <= maxX && space.y >= minY && space.y <= maxY
      );
    });

    setSelectedSpaces(selected);

    setSelectionRect((currentRect) => ({ ...currentRect, visible: false }));
  };

  // Single Space Click

  const handleSpaceClick = (spaceId) => {
    if (!isSelectionMode) {
      return null;
    } else {
      const newSelectedSpaces = spaces.filter((space) => space.id === spaceId);
      setSelectedSpaces(newSelectedSpaces);
    }
  };

  // Dragging Logic

  const handleDragStart = (e) => {
    // Capture the starting position for the dragged space
    const draggedSpace = spaces.find((s) => s.id === e.target.id());
    if (draggedSpace) {
      e.target.setAttr('startX', e.target.x());
      e.target.setAttr('startY', e.target.y());
    }

    // For group dragging, ensure we're not resetting the initial positions
    // of other selected spaces unintentionally
  };

  const handleDragMove = (e) => {
    // Calculate deltas based on the drag start attributes
    const deltaX = e.target.x() - e.target.getAttr('startX');
    const deltaY = e.target.y() - e.target.getAttr('startY');

    // Apply delta to the dragged space and all selected spaces if they are not the dragged space
    const updatedSpaces = spaces.map((space) => {
      if (
        space.id === e.target.id() ||
        selectedSpaces.some((sel) => sel.id === space.id)
      ) {
        return { ...space, x: space.x + deltaX, y: space.y + deltaY };
      }
      return space;
    });

    setSelectionRect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      visible: false,
    });
    setSpaces(updatedSpaces);

    // Correct the initial position to prevent compounding of deltas
    e.target.setAttr('startX', e.target.x());
    e.target.setAttr('startY', e.target.y());
  };

  // export as Data Url
  // const handleImageExport = () => {
  //   const stage = stageRef.current;
  //   if (stage) {
  //     const dataURL = stage.toDataURL({ pixelRatio: 3 }); // Increases the output resolution
  //     setFinalImage(dataURL);
  //     console.log(dataURL); // Use this dataURL as the src for an <img> element or store it for later use
  //     // You might want to send this dataURL to your server for storage
  //   }
  // };

  // Export as SVG

  // const handleImageExport = () => {
  //   const stage = stageRef.current.getStage();
  //   let svgString = stage.toSVG(); // This generates the SVG string

  //   // Here you would handle the SVG string, e.g., displaying it, sending it to a server, etc.
  //   console.log(svgString); // For demonstration purposes

  //   // Example: Displaying the SVG in an img tag (You might need to encode the SVG string or handle it according to your needs)
  //   const encodedData = encodeURIComponent(svgString);
  //   setFinalImage(`data:image/svg+xml;utf8,${encodedData}`);
  // };

  const handleImageExportAndNavigate = async () => {
    const stage = stageRef.current.getStage();
    if (!stage) {
      console.error('Stage not available');
      return;
    }

    // Export as Blob SVG
    const svgBlob = await exportStageSVG(stage, true, {
      onBefore: ([stage, layer]) => {
        console.log('Before export');
      },
      onAfter: ([stage, layer]) => {
        console.log('After export');
      },
    });

    if (svgBlob) {
      console.log('SVG Blob:', svgBlob);
      const url = URL.createObjectURL(svgBlob);
      console.log('Blob URL:', url);

      // Assuming navigate is correctly obtained via useNavigate()
      navigate('/interactive-map2', { state: { finalImage: url } });
    } else {
      console.error('Failed to export SVG');
    }
  };

  return (
    <div className="flex flex-col">
      <Toolbar
        onReset={resetSpaces}
        onUndo={undoLastAction}
        onSelectMode={toggleSelectionMode}
        isSelectMode={isSelectionMode}
        onAlignHorizontal={alignSelectedSpacesHorizontally}
        onAlignVertical={alignSelectedSpacesVertically}
        onAlignEvenlyHorizontally={alignSelectedSpacesEvenlyHorizontally}
        onAlignEvenlyVertically={alignSelectedSpacesEvenlyVertically}
        onFinish={handleImageExportAndNavigate}
      />
      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        <div className="md:flex-1 border border-gray-300 shadow-lg rounded-lg">
          <Stage
            width={stageSize.width}
            height={stageSize.height}
            onMouseDown={isSelectionMode ? onMouseDown : addSpace}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            ref={stageRef}
            className="flex items-center justify-center border h-full w-full md:mb-0 bg-red-200"
          >
            {/* Ensure the layer takes up the entire stage */}
            <Layer>
              {/* Scale and center the image inside the stage */}
              {image && (
                <Image
                  image={image}
                  scaleX={imageScale}
                  scaleY={imageScale}
                  x={stageSize.width / 2 - image.width / 2}
                  y={0}
                  // x={
                  //   (stageSize.width -
                  //     image.width *
                  //       Math.min(
                  //         stageSize.width / image.width,
                  //         stageSize.height / image.height
                  //       )) /
                  //   2
                  // } // Center horizontally
                  // y={
                  //   stageSize.height -
                  //   image.height *
                  //     Math.min(
                  //       stageSize.width / image.width,
                  //       stageSize.height / image.height
                  //     )
                  // } // Center vertically
                />
              )}

              {/* Render circles */}
              {spaces.map((space) => (
                <Circle
                  key={space.id}
                  id={space.id}
                  x={space.x}
                  y={space.y}
                  radius={6}
                  fill={
                    selectedSpaces.some(
                      (selectedSpace) => selectedSpace.id === space.id
                    )
                      ? 'green'
                      : 'blue'
                  }
                  strokeWidth={
                    selectedSpaces.some(
                      (selectedSpace) => selectedSpace.id === space.id
                    )
                      ? 2
                      : 0
                  }
                  stroke={
                    selectedSpaces.some(
                      (selectedSpace) => selectedSpace.id === space.id
                    )
                      ? 'black'
                      : undefined
                  }
                  draggable={true}
                  onClick={() => handleSpaceClick(space.id)}
                  onDragStart={handleDragStart}
                  onDragMove={handleDragMove}
                />
              ))}

              {/* Selection Rectangle */}
              {selectionRect.visible && (
                <Rect
                  x={selectionRect.x}
                  y={selectionRect.y}
                  width={selectionRect.width}
                  height={selectionRect.height}
                  fill="rgba(255, 189, 89, 0.5)"
                />
              )}
            </Layer>
          </Stage>
        </div>

        <div className="lg:w-1/3 lg:pl-4">
          <RadioGroup
            value={selectedSpaceType}
            onChange={setSelectedSpaceType}
            className="w-full"
          >
            <Label className="sr-only">Select Space Type</Label>
            <div className="flex flex-col">
              {spaceTypes.map((type) => (
                <Radio
                  key={type.id}
                  value={type}
                  disabled={type.totalQuantity === 0}
                  className={({ disabled, checked }) =>
                    `cursor-pointer py-6 px-8 mb-4 rounded-lg shadow-lg border ${
                      checked
                        ? 'bg-yellow-03 border-gray-500 text-white'
                        : 'bg-white text-gray-800 border-gray-200'
                    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div
                            className={`flex items-center justify-center h-4 w-4 p-4 border rounded-full ${
                              checked ? 'border-white' : 'border-gray-400'
                            }`}
                          >
                            <span className={`${checked ? 'font-bold' : ''}`}>
                              {getRemainingSpacesByType(type.name)}
                            </span>
                          </div>
                          <div className="text-sm ml-6 pl-6 border-l-2">
                            <Label
                              as="p"
                              className={`text-lg mb-2 ${
                                checked
                                  ? 'text-white font-bold'
                                  : 'text-gray-900'
                              }`}
                            >
                              {type.name}
                            </Label>
                            <Description
                              as="span"
                              className={`inline ${
                                checked ? 'text-white' : 'text-gray-500'
                              }`}
                            >
                              <span>{type.size}</span>{' '}
                              <span aria-hidden="true">&middot;</span>{' '}
                              <span>{type.price}€</span>
                            </Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Radio>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default CircleSolution;

// Rendering the final image
//   {!!finalImage && (
//   <div className="relative bg-red-100">
//     <img
//       src={finalImage}
//       alt="Event Layout"
//       className="bg-blue-100"
//       style={{ width: 600, height: 400 }}
//     />
//     {spaces.map((space) => (
//       <div
//         key={space.id}
//         style={{
//           left: `${space.x}px`, // Adjust these values based on your scaling
//           top: `${space.y}px`,
//           width: '10px', // Adjust based on your space representation
//           height: '10px', // Adjust based on your space representation
//           backgroundColor: 'rgba(255, 0, 0, 0.5)', // For visibility, adjust as needed
//         }}
//         onClick={() => handleSpaceClick(space)}
//       />
//     ))}
//   </div>
// )}
