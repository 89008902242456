import React, { useEffect } from 'react';
import moment from 'moment';
import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from '../../../redux/actions/notifications';

const NotificationItem = ({
  id,
  type,
  message,
  createdAt,
  read,
  markAsRead,
  deleteNotification,
  approveNotification,
  rejectNotification,
}) => {
  const iconTypes = {
    success: <CheckCircleIcon className="w-8 h-8 text-green-800" />,
    pending: <ClockIcon className="w-8 h-8 text-yellow-400" />,
    rejected: <XCircleIcon className="w-8 h-8 text-red-800" />,
  };

  return (
    <div
      className={`flex flex-col sm:flex-row sm:justify-between items-center p-4 rounded-md shadow-md ${
        !read ? 'bg-yellow-50' : 'bg-gray-50'
      }`}
    >
      <div className="flex items-center w-full space-x-4">
        {iconTypes[type] || iconTypes['pending']}
        <div className="flex-1">
          <p className="font-semibold text-gray-800">{message}</p>
          <p className="text-xs text-gray-500">{moment(createdAt).fromNow()}</p>
        </div>
      </div>

      {/* {type === 'pending' && ( */}
      <div className="flex w-1/4 space-x-2 mt-3 sm:mt-0">
        <button
          onClick={() => approveNotification(id)}
          className="px-3 py-1 text-sm font-semibold text-white bg-green-400 rounded-md hover:bg-green-700"
        >
          Approve
        </button>
        <button
          onClick={() => rejectNotification(id)}
          className="px-3 py-1 text-sm font-semibold text-white bg-red-400 rounded-md hover:bg-red-700"
        >
          Reject
        </button>
      </div>
      {/* )} */}

      <Menu
        as="div"
        className="relative inline-block text-left mt-3 sm:mt-0 sm:ml-4"
      >
        <MenuButton className="flex items-center focus:outline-none">
          <EllipsisVerticalIcon className="w-6 h-6 text-gray-400 hover:text-gray-600" />
        </MenuButton>
        <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={() => markAsRead(id)}
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } flex w-full px-4 py-2 text-sm`}
                >
                  Mark as Read
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={() => deleteNotification(id)}
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } flex w-full px-4 py-2 text-sm`}
                >
                  Delete
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const loading = useSelector((state) => state.notifications.loading);
  const error = useSelector((state) => state.notifications.error);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const handleMarkAsRead = (id) => {
    console.log(`Marking notification ${id} as read`);
  };

  const handleDeleteNotification = (id) => {
    console.log(`Deleting notification ${id}`);
  };

  const handleApproveNotification = (id) => {
    console.log(`Approving notification ${id}`);
  };

  const handleRejectNotification = (id) => {
    console.log(`Rejecting notification ${id}`);
  };

  return (
    <div className="w-full bg-white px-6 pb-6 rounded-br-3xl text-gray-600 space-y-2 overflow-y-auto">
      {loading ? (
        <p>Loading notifications...</p>
      ) : error ? (
        <p>Error fetching notifications: {error}</p>
      ) : notifications?.length > 0 ? (
        notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            {...notification}
            markAsRead={handleMarkAsRead}
            deleteNotification={handleDeleteNotification}
            approveNotification={handleApproveNotification}
            rejectNotification={handleRejectNotification}
          />
        ))
      ) : (
        <p>No notifications to display.</p>
      )}
    </div>
  );
};

export default Notifications;
