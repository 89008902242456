import React from 'react';
import { Link } from 'react-router-dom';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from '@headlessui/react';
import PropTypes from 'prop-types';
import Rating from 'components/Rating';
import ProgressBar from 'components/ProgressBar';
import CategoryIcon from 'components/CategoryIcon';

import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

const DEFAULT_IMAGE = 'https://via.placeholder.com/150?text=No+Image';

const EventCardWrapper = ({ event, hasNavigation, small, isActive }) => {
  const eventDetailUrl = `/event/${event.id}`;
  const eventEditUrl = `/event/${event.id}/edit`;

  const handleDeleteEvent = () => {
    // Replace with your delete logic
    console.log(`Deleting event: ${event.id}`);
  };

  if (!event) {
    return null;
  }

  const EventCard = () => {
    return (
      <div
        className={`flex flex-col rounded-3xl overflow-visible max-w-sm mx-auto scale-90 sm:scale-95 md:scale-100 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 ${
          isActive ? 'border-2 border-gray-400 shadow-xl' : ''
        }`}
      >
        <div className="flex">
          <div className="flex-none w-1/3 p-4">
            <img
              className="object-cover h-full w-full rounded-md"
              src={event.image || DEFAULT_IMAGE}
              alt={event.title || 'Event'}
            />
          </div>
          <div className="w-2/3 p-4 flex flex-col justify-between relative overflow-visible">
            <div>
              <h3 className="font-bold text-lg text-gray-800">{event.title}</h3>
              <p
                className="text-gray-500 text-sm truncate max-w-full"
                title={event.location}
              >
                {event.location}
              </p>
              <Rating rating={event.rating} />
              <div className="mt-2">
                <span className="px-2 py-1 rounded-2xl bg-gray-200 text-gray-600 text-xs">
                  {event.dateRange}
                </span>
              </div>
            </div>

            <div className="mt-2 flex flex-row space-x-1">
              <CategoryIcon macro="arts_crafts" className="w-5 h-5" />
              <CategoryIcon macro="fashion_apparel" className="w-5 h-5" />
              <CategoryIcon macro="food_drinks" className="w-5 h-5" />
              <CategoryIcon macro="home_lifestyle" className="w-5 h-5" />
              <CategoryIcon macro="tech_gadgets" className="w-5 h-5" />
            </div>

            {/* 3-Dot Dropdown Menu */}
            <Menu as="div" className="absolute top-4 right-4 z-100">
              <MenuButton>
                <EllipsisHorizontalIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
              </MenuButton>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 w-26 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <MenuItem>
                    {({ active }) => (
                      <Link
                        to={eventDetailUrl}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex items-center px-4 py-2 text-xs text-gray-700`}
                      >
                        <EyeIcon className="w-4 h-4 mr-2" />
                        View
                      </Link>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <Link
                        to={eventEditUrl}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex items-center px-4 py-2 text-xs text-gray-700`}
                      >
                        <PencilIcon className="w-4 h-4 mr-2" />
                        Edit
                      </Link>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={handleDeleteEvent}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex items-center px-4 py-2 text-xs text-gray-700 w-full`}
                      >
                        <TrashIcon className="w-4 h-4 mr-2" />
                        Delete
                      </button>
                    )}
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>

        {!small && (
          <>
            <div className="px-4 pb-4">
              <p className="text-gray-500 text-sm truncate-2-lines">
                {event.description}
              </p>
            </div>

            <div className="px-4 pb-4 flex justify-between items-center">
              <div className="w-1/3">
                <ProgressBar
                  current={Math.floor(Math.random() * 100)}
                  total={100}
                />
              </div>

              <div className="ml-4 p-2 bg-yellow-100 rounded-2xl cursor-pointer">
                <p className="text-gray-900 text-[10px] font-bold">
                  Prices start at:
                </p>
                <p className="text-gray-900 text-xs font-bold">
                  {event.price}€
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return hasNavigation ? (
    <Link to={eventDetailUrl} className="no-underline text-current">
      {EventCard()}
    </Link>
  ) : (
    EventCard()
  );
};

EventCardWrapper.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    location: PropTypes.string,
    dateRange: PropTypes.string,
    capacity: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    description: PropTypes.string,
  }),
  hasNavigation: PropTypes.bool,
  isActive: PropTypes.bool,
  small: PropTypes.bool,
};

export default EventCardWrapper;
