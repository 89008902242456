import React, { useState } from 'react';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react';

import Rating from 'components/Rating';
import CategoryIcon from 'components/CategoryIcon';
import CustomSlider from 'components/Swiper';
import ModalDialog from 'components/DialogModal';
import CategoryAccordion from 'components/CategoryAccordion';
import EventCardWrapper from 'components/EventCardWrapper';

import { getMacroName, getMicroName } from 'utils/getCategoryName';

import {
  ChevronUpIcon,
  ChevronDownIcon,
  ShareIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import { MOCK_BRAND, EVENT_LIST } from '../../mockData';

import facebookIcon from '../../assets/icons/socials/facebook_icon.svg';
import instagramIcon from '../../assets/icons/socials/instagram_icon.svg';
import twitterIcon from '../../assets/icons/socials/twitter_icon.svg';

import image1 from '../../assets/images/olkin_1.webp';
import image2 from '../../assets/images/olkin_2.jpeg';
import image3 from '../../assets/images/olkin_3.webp';

const MOCK_EVENTS_INVITE = EVENT_LIST.slice(0, 3);

const BrandDetail = ({ brand }) => {
  console.log('MOCK_BRAND', MOCK_BRAND);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modalContent, setModalContent] = useState({ content: '', title: '' });
  const [isInviteMenuOpen, setIsInviteMenuOpen] = useState(false);

  const mock_images = [
    image1,
    image2,
    image3,
    image1,
    image2,
    image3,
    image1,
    image2,
    image3,
  ];

  const handleShowMoreModal = (content, title) => {
    setModalContent({ content: content, title: title });
    setShowInfoModal(true);
  };

  const handleCloseMoreModal = () => {
    setShowInfoModal(false);
    setModalContent(null);
  };

  const renderEventInviteContainer = (active, event) => {
    return (
      <button
        className={`${
          active ? 'text-gray-900' : 'text-gray-700'
        } flex w-full bg-gray-100 rounded-md items-center px-2 py-2 text-sm mb-2`}
      >
        <div className="flex items-center">
          <img
            src={event.image}
            alt={event.title}
            className="h-10 w-10 object-cover rounded-full mr-2"
          />
          <div className="flex  items-start flex-col">
            <span className="font-semibold">{event.title}</span>
            <span className="text-xs text-gray-400">{event.location}</span>
            <span className="text-xs px-1 rounded-lg text-white font-bold bg-gray-500">
              {event.dateRange}
            </span>
          </div>
          <button className=" w-1/4 ml-auto bg-green-500 text-white px-2 py-1 rounded-md text-xs">
            Send invitation
          </button>
        </div>
      </button>
    );
  };

  const renderHeaderSocials = () => {
    return (
      <div className="flex flex-row space-x-2 mt-2">
        <button className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
          <img src={facebookIcon} alt="Facebook" className="w-8 h-8" />
        </button>
        <button className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
          <img src={instagramIcon} alt="Instagram" className="w-8 h-8" />
        </button>
        <button className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
          <img src={twitterIcon} alt="Twitter" className="w-8 h-8" />
        </button>
      </div>
    );
  };

  const renderHeaderInfo = () => {
    return (
      <div className="flex justify-between items-center py-4">
        <div className="flex">
          <div className="rounded-2xl bg-gray-100">
            <img
              src={MOCK_BRAND.image}
              alt={MOCK_BRAND.title}
              className="h-32 w-32 object-cover"
            />
          </div>
          <div className="flex flex-col justify-between ml-4 py-3">
            <div className="flex flex-col">
              <h1 className="text-2xl font-bold">{MOCK_BRAND.name}</h1>
              <p className="text-gray-400 text-sm mb-2">
                {MOCK_BRAND.location}
              </p>
              <Rating rating={MOCK_BRAND.rating} />
            </div>
            <div className="flex flex-row">
              {MOCK_BRAND.categories.map((cat, idx) => (
                <div className="flex flex-row space-x-1 mt-1" key={idx}>
                  <CategoryIcon macro={cat.icon} className="w-8 h-8" />
                  <CategoryIcon
                    macro={cat.icon}
                    micro={cat.micro.icon}
                    className="w-8 h-8"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className="flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-orange-500 text-sm font-medium text-white hover:bg-orange-600"
                onClick={() => setIsInviteMenuOpen(!isInviteMenuOpen)}
              >
                Invite brand
                {isInviteMenuOpen ? (
                  <ChevronUpIcon className="w-6 h-6 ml-2" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6 ml-2" />
                )}
              </MenuButton>
            </div>
            <MenuItems className="origin-top-right absolute right-0 mt-2 w-72 rounded-md px-4 py-2 z-50 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {MOCK_EVENTS_INVITE.map((event, idx) => (
                  <MenuItem key={idx}>
                    {({ active }) => renderEventInviteContainer(active, event)}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Menu>
          {renderHeaderSocials()}
          <div className="flex flex-row space-x-2 items-center mt-2">
            <p className="text-gray-500">share</p>
            <ShareIcon className="w-3 h-3 text-gray-500 cursor-pointer" />
          </div>
        </div>
      </div>
    );
  };

  const renderMedia = () => {
    return (
      <div className="flex flex-col mt-4">
        <CustomSlider slidesPerView={5}>
          {mock_images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="object-cover h-full w-full"
            />
          ))}
        </CustomSlider>
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="flex flex-col pl-8">
        {MOCK_BRAND.categories &&
          MOCK_BRAND.categories.map((cat, idx) => (
            <div
              key={idx}
              className="flex flex-row items-center mt-2 space-x-2"
            >
              <CategoryIcon macro={cat.icon} className="w-8 h-8" />
              <CategoryIcon
                macro={cat.icon}
                micro={cat.micro ? cat.micro.icon : null}
                className="w-8 h-8"
              />
            </div>
          ))}
      </div>
    );
  };

  const renderSection = ({ title, content, moreButton, moreContent }, idx) => {
    return (
      <div
        key={idx}
        className={`flex flex-col mr-20 mt-6 border-b-2 border-gray-300 ${moreButton ? '' : 'pb-6'}`}
      >
        <h2 className="mb-6 text-2xl text-yellow-500 font-bold">{title}</h2>
        <div
          className={
            title === 'Description' || title === 'History'
              ? 'line-clamp text-gray-500'
              : 'text-gray-500'
          }
        >
          {content}
        </div>
        {moreButton && (
          <div
            onClick={() => handleShowMoreModal(moreContent, title)}
            className="cursor-pointer underline text-gray-800 text-md mt-4 pb-6 flex justify-end"
          >
            {moreButton}
            <PlusIcon className="w-5 h-5 ml-2" />
          </div>
        )}
      </div>
    );
  };

  const renderLinks = (link, idx) => {
    return (
      <div key={idx} className="flex flex-col">
        <a href={link.url} className="text-blue-500 underline">
          {link.name}
        </a>
      </div>
    );
  };

  const renderUpcomingEvents = () => {
    const eventList = EVENT_LIST.slice(0, 5); // Assuming EVENT_LIST is an array and slicing to get the first 5 events
    return (
      <div className="flex flex-col py-4">
        <h1 className="text-2xl font-bold text-yellow-500">Upcoming Events</h1>
        <CustomSlider slidesPerView={2} isEvents>
          {eventList.map((event, index) => (
            <EventCardWrapper small event={event} key={index} />
          ))}
        </CustomSlider>
      </div>
    );
  };

  const sections = [
    {
      title: 'Foundation',
      content: MOCK_BRAND.location + `${', '}` + MOCK_BRAND.foundationYear,
    },
    {
      title: 'Website',
      content: MOCK_BRAND.website,
    },
    {
      title: 'Description',
      content: MOCK_BRAND.longDescription,
      moreButton: 'Show more',
      moreContent: MOCK_BRAND.longDescription,
    },
    {
      title: 'History',
      content: MOCK_BRAND.history,
      moreButton: 'Show more',
      moreContent: MOCK_BRAND.history,
    },
    {
      title: 'Macro and Sub Categories',
      content: renderCategories(),
      moreButton: 'Show more',
      moreContent: <CategoryAccordion />,
    },
    {
      title: 'Relevant Links',
      content: MOCK_BRAND.links.map((link) => renderLinks(link)),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-4">
      {renderHeaderInfo()}
      {renderMedia()}
      <div className="grid grid-cols-2 gap-2">
        {sections.map((section, idx) => renderSection(section, idx))}
      </div>
      {renderUpcomingEvents()}
      {showInfoModal && (
        <ModalDialog
          isOpen={showInfoModal}
          closeModal={handleCloseMoreModal}
          title={modalContent.title}
          content={modalContent.content}
        />
      )}
    </div>
  );
};

export default BrandDetail;
