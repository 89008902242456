import {
  CREATE_SPACE_SUCCESS,
  CREATE_SPACE_FAIL,
  GET_SPACES_REQUEST,
  GET_SPACES_SUCCESS,
  GET_SPACES_FAIL,
  GET_SPACE_SUCCESS,
  GET_SPACE_FAIL,
  UPDATE_SPACE_SUCCESS,
  UPDATE_SPACE_FAIL,
  DELETE_SPACE_SUCCESS,
  DELETE_SPACE_FAIL,
} from '../actions/spaces';

const initialState = {
  spaces: [],
  space: null,
  loading: false,
  error: null,
};

// Reducer
const spaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPACES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SPACES_SUCCESS:
      return {
        ...state,
        spaces: action.payload,
        loading: false,
        error: null,
      };
    case GET_SPACES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SPACE_SUCCESS:
      return {
        ...state,
        space: action.payload,
        loading: false,
        error: null,
      };
    case GET_SPACE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_SPACE_SUCCESS:
      return {
        ...state,
        spaces: [...state.spaces, action.payload],
        loading: false,
        error: null,
      };
    case CREATE_SPACE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SPACE_SUCCESS:
      return {
        ...state,
        spaces: state.spaces.map((space) =>
          space.id === action.payload.id ? action.payload : space
        ),
        loading: false,
        error: null,
      };
    case UPDATE_SPACE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SPACE_SUCCESS:
      return {
        ...state,
        spaces: state.spaces.filter((space) => space.id !== action.payload),
        loading: false,
        error: null,
      };
    case DELETE_SPACE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default spaceReducer;
