import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useModal } from '../../context/ModalContext';
import { useToast } from '../../context/toastContext';
import { login } from '../../redux/actions/auth';
import { validateEmail } from 'utils/emailValidation';
import { ClipLoader } from 'react-spinners';

import facebookIcon from 'assets/icons/socials/facebook_icon.svg';
import googleIcon from 'assets/icons/socials/google_icon.svg';

const LoginModal = () => {
  const [userType, setUserType] = useState('brand');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoginModalOpen, hideLoginModal } = useModal();

  const dispatch = useDispatch();
  const addToast = useToast();
  const disabled = !email || !password;

  const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);
  const handlePasswordChange = useCallback(
    (e) => setPassword(e.target.value),
    []
  );

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      await dispatch(login(email, password));

      addToast('Login successful!', 'success');
      closeDialog();
    } catch (error) {
      setErrorMessage(error.message);
      addToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const closeDialog = () => {
    hideLoginModal();
    setErrorMessage('');
  };

  return (
    <Transition appear show={isLoginModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeDialog}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400 backdrop-blur-3xl opacity-90" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-6 text-left align-middle shadow-2xl transition-all">
                <DialogTitle
                  as="div"
                  className="flex justify-center items-center text-xl font-medium text-yellow-02"
                >
                  Sign in
                  <button
                    type="button"
                    className="absolute right-0 top-0 border-transparent bg-transparent px-4 py-2 text-sm font-medium text-yellow-900 hover:text-yellow-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2"
                    onClick={closeDialog}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </DialogTitle>
                <div className="mt-4">
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      className={`w-40 font-bold inline-flex justify-center rounded-3xl border border-transparent py-3 text-gray-600 ${userType === 'brand' ? 'bg-yellow-02 text-white' : 'bg-gray-200'} focus:outline-none`}
                      onClick={() => setUserType('brand')}
                    >
                      I'm a Brand
                    </button>
                    <button
                      type="button"
                      className={`w-40 font-bold inline-flex justify-center ml-2 rounded-3xl border border-transparent py-3 text-gray-600 ${userType === 'promoter' ? 'bg-yellow-02 text-white' : 'bg-gray-200'}`}
                      onClick={() => setUserType('promoter')}
                    >
                      I'm a Promoter
                    </button>
                  </div>
                  <form className="mt-8 px-8" onSubmit={handleLogin}>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmailChange}
                      className="mt-2 py-2 px-4 block w-full rounded-3xl bg-white border-gray-300 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                      required
                      aria-invalid={!!errorMessage}
                      aria-describedby="email-error"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      className="mt-2 py-2 px-4 block w-full rounded-3xl bg-white border-gray-300 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                      required
                      aria-invalid={!!errorMessage}
                      aria-describedby="password-error"
                    />
                    {errorMessage && (
                      <div className="mt-2 text-red-600" id="error-message">
                        {errorMessage}
                      </div>
                    )}
                    <div className="flex mt-4 items-center justify-center">
                      <button
                        type="submit"
                        className={`w-full font-bold inline-flex justify-center rounded-3xl border border-transparent py-2 text-white ${disabled ? 'bg-gray-200' : 'bg-yellow-02 text-white'}`}
                        disabled={disabled}
                      >
                        {isLoading ? <ClipLoader color="#FFFFFF" /> : 'Confirm'}
                      </button>
                    </div>
                    <div className="flex mt-2 items-center justify-between">
                      <a href="/" className="text-xs text-blue-700">
                        Forgot your password?
                      </a>
                      <a href="/register" className="text-xs text-blue-700">
                        Don't have an account yet?
                      </a>
                    </div>
                    <div className="flex flex-row items-center justify-between mt-2">
                      <div className="w-full bg-gray-300 h-[2px]" />
                      <div className="p-6 text-gray-400 font-bold flex justify-center items-center">
                        or
                      </div>
                      <div className="w-full bg-gray-300 h-[2px]" />
                    </div>
                    <div className="flex flex-col items-center mt-4 space-y-2">
                      <button
                        type="button"
                        className="w-60 inline-flex items-center rounded-md border border-transparent bg-blue-700/80 px-2 py-3 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <img
                          src={facebookIcon}
                          alt="Facebook"
                          className="w-6 h-6 mx-4"
                        />
                        Login with Facebook
                      </button>
                      <button
                        type="button"
                        className="w-60 inline-flex items-center rounded-md border border-transparent bg-red-500/80 px-2 py-3 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      >
                        <img
                          src={googleIcon}
                          alt="Google"
                          className="w-6 h-6 mx-4"
                        />
                        Login with Google
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginModal;
