import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Rating from 'components/Rating';
import ProgressBar from 'components/ProgressBar';
import CategoryIcon from 'components/CategoryIcon';

const EventCardList = ({ event, hasNavigation, isGuest }) => {
  if (!event) {
    return null;
  }

  const eventDetailUrl = `/event/${event.id}`;

  const EventListCard = () => {
    return (
      <div className="flex flex-row rounded-lg items-center shadow-md bg-white w-full p-4">
        <div className="flex flex-none pr-4">
          <span className="text-gray-500 font-bold">{event.dateRange}</span>
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <img
              className="w-12 h-12 object-cover"
              src={event.image}
              alt={event.title}
            />
          </div>
          <div className="flex flex-col pl-2">
            <h3 className="font-bold text-lg">{event.title}</h3>
            <p
              className="text-gray-400 text-sm truncate max-w-28"
              title={event.location}
            >
              {event.location}
            </p>
            <Rating rating={event.rating} />
          </div>
          <div className="flex w-2/3 flex-row items-center">
            <div className="flex w-1/4 flex-row space-x-2 ">
              <CategoryIcon macro="arts_crafts" className="w-6 h-6" />
              <CategoryIcon macro="fashion_apparel" className="w-6 h-6" />
              <CategoryIcon macro="food_drinks" className="w-6 h-6" />
              <CategoryIcon macro="home_lifestyle" className="w-6 h-6" />
              <CategoryIcon macro="tech_gadgets" className="w-6 h-6" />
            </div>
            <p className="text-gray-400 text-sm w-3/4">{event.description}</p>
          </div>
          <div className="flex flex-none items-center space-x-4">
            <div className="w-24">
              <ProgressBar
                current={Math.floor(Math.random() * 100)}
                total={100}
              />
            </div>
            <div className="ml-4 p-2 bg-yellow-01 rounded-2xl cursor-pointer text-center">
              <p className="text-gray-900 text-[10px] font-bold">
                Prices start at:
              </p>
              <p className="text-gray-900 text-md font-bold">{event.price}€</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return hasNavigation ? (
    <Link to={eventDetailUrl} className="no-underline text-current">
      {EventListCard()}
    </Link>
  ) : (
    EventListCard()
  );
};

EventCardList.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    location: PropTypes.string,
    dateRange: PropTypes.string,
    capacity: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    description: PropTypes.string,
  }),
  hasNavigation: PropTypes.bool,
  isGuest: PropTypes.bool,
};

export default EventCardList;
