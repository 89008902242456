import axios from '../../utils/axiosConfig';

// Action Types

export const GET_PROMOTER_REQUEST = 'GET_PROMOTER_REQUEST';
export const GET_PROMOTER_SUCCESS = 'GET_PROMOTER_SUCCESS';
export const GET_PROMOTER_FAIL = 'GET_PROMOTER_FAIL';

export const getPromoter = (promoterId) => async (dispatch, getState) => {
  console.log('🚀🚀 ~  getPromoter ~  promoterId:', promoterId);
  dispatch({ type: GET_PROMOTER_REQUEST });
  const { auth } = getState();
  const token = auth.token;
  try {
    const res = await axios.get(`/promoters/profile/${promoterId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_PROMOTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTER_FAIL,
      payload: err.response?.data?.message || 'Failed to fetch promoter',
    });
  }
};
