import React from 'react';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';

const Tabs = () => (
  <Tab.Group>
    <Tab.List className='flex space-x-1 rounded-xl bg-blue-900/20 p-1'>
      <Tab as={Fragment}>
        {({ selected }) => (
          <button
            className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${
              selected
                ? 'bg-white shadow'
                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
            }`}
          >
            Tab 1
          </button>
        )}
      </Tab>
      <Tab as={Fragment}>
        {({ selected }) => (
          <button
            className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${
              selected
                ? 'bg-white shadow'
                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
            }`}
          >
            Tab 1
          </button>
        )}
      </Tab>
      <Tab as={Fragment}>
        {({ selected }) => (
          <button
            className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${
              selected
                ? 'bg-white shadow'
                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
            }`}
          >
            Tab 1
          </button>
        )}
      </Tab>
    </Tab.List>
    <Tab.Panels className='mt-2'>
      <Tab.Panel className='rounded-xl bg-gray-100 p-3'>
        <p>Tab 1 Content</p>
      </Tab.Panel>
      <Tab.Panel className='rounded-xl bg-gray-100 p-3'>
        <p>Tab 2 Content</p>
      </Tab.Panel>
      <Tab.Panel className='rounded-xl bg-gray-100 p-3'>
        <p>Tab 3 Content</p>
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
);

export default Tabs;
