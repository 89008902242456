import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import BrandImage from 'components/BrandImage';

const BrandImageCarousel = ({ brands, autoSlide }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({
        left: scrollLeft - clientWidth / 2, // Scroll by half the container width
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({
        left: scrollLeft + clientWidth / 2, // Scroll by half the container width
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (autoSlide) {
      const interval = setInterval(() => {
        if (scrollContainerRef.current) {
          const { scrollLeft, clientWidth, scrollWidth } =
            scrollContainerRef.current;
          const maxScrollLeft = scrollWidth - clientWidth;

          if (scrollLeft + clientWidth >= maxScrollLeft) {
            scrollContainerRef.current.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          } else {
            scrollRight();
          }
        }
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [autoSlide]);

  return (
    <div className="flex flex-col">
      {!autoSlide && (
        <div className="flex justify-end mb-4">
          <button
            onClick={scrollLeft}
            className="px-4 py-2 justify-center items-center rounded-full text-xl ml-2 bg-white"
          >
            {'<'}
          </button>
          <button
            onClick={scrollRight}
            className="px-4 py-2 justify-center items-center rounded-full text-xl ml-2 bg-white"
          >
            {'>'}
          </button>
        </div>
      )}
      <div
        ref={scrollContainerRef}
        className="flex overflow-hidden flex-row scroll-smooth whitespace-nowrap hide-scroll-bar"
      >
        {brands.map((brand, index) => (
          <div key={index} className="flex-shrink-0">
            <BrandImage brandImage={brand.logo} />
          </div>
        ))}
        {brands.map((brand, index) => (
          <div key={`${index}-duplicate`} className="flex-shrink-0">
            <BrandImage brandImage={brand.logo} />
          </div>
        ))}
      </div>
    </div>
  );
};

BrandImageCarousel.propTypes = {
  brands: PropTypes.array.isRequired,
  autoSlide: PropTypes.bool,
};

export default BrandImageCarousel;
