import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BoardHeader = ({ userType, info, section }) => {
  const navigate = useNavigate();

  const getButtonDetails = () => {
    switch (section) {
      case 'promoterInfo':
      case 'brandInfo':
        return {
          buttonText: 'Edit Profile',
          action: () => console.log('Edit Profile'),
        };
      case 'myEvents':
        return {
          buttonText: 'Create Event',
          action: () => navigate('/create-event'),
        };
      case 'paymentDetails':
        return {
          buttonText: 'Update Payment Info',
          action: () => console.log('Update Payment'),
        };
      case 'preferences':
        return {
          buttonText: 'Edit Preferences',
          action: () => console.log('Edit Preferences'),
        };
      default:
        return { buttonText: '', action: null };
    }
  };

  const { buttonText, action } = getButtonDetails();
  const logo = userType === 'promoter' ? info?.avatar : info?.logo;

  if (!info) return null;

  return (
    <div className="flex justify-between space-x-4 bg-white p-6 rounded-tr-3xl">
      <div className="flex items-center">
        <img
          src={logo || 'https://via.placeholder.com/150'}
          alt="Avatar"
          className="h-20 w-20 object-cover rounded-full"
        />
        <h1 className="text-3xl font-bold ml-6 text-gray-700">
          {info.companyName || info.username}
        </h1>
      </div>
      {buttonText && (
        <div className="flex items-end">
          <button
            onClick={action}
            className="text-gray-400 text-xs font-bold underline"
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

BoardHeader.propTypes = {
  userType: PropTypes.string.isRequired,
  info: PropTypes.object,
  section: PropTypes.string.isRequired,
};

export default BoardHeader;
