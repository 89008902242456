import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from '@react-google-maps/api';
import { ClipLoader } from 'react-spinners';

import EventCardWrapper from 'components/EventCardWrapper';
import EventSearchBar from 'components/EventSearchBar';
import Rating from 'components/Rating';

import { EVENT_LIST } from '../../mockData';

import './mapStyle.css';
import '../../index.css';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const initialCenter = {
  lat: 38.736946,
  lng: -9.142685,
};

const EventListMap = () => {
  const loaderOptions = useMemo(
    () => ({
      googleMapsApiKey: 'AIzaSyD3DixK8JAoArLgRtuo2uYuPcpESSmKn5k',
      id: 'google-map-script',
      version: 'weekly',
      libraries: ['places'],
    }),
    []
  );

  const { isLoaded, loadError } = useJsApiLoader(loaderOptions);

  const [selectedEvent, setSelectedEvent] = useState(EVENT_LIST[0] || null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState(initialCenter);
  const zoom = 15;

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    const newCenter = {
      lat: event.locationCoords?.lat,
      lng: event.locationCoords?.lng,
    };
    setCenter(newCenter);
  };

  const handleMarkerClick = useCallback(
    (eventId) => {
      if (activeMarker === eventId) {
        setActiveMarker(null);
      } else {
        setActiveMarker(eventId);
      }
    },
    [activeMarker]
  );

  useEffect(() => {
    if (selectedEvent) {
      // Add the title and image to the InfoWindow header
      const intervalId = setInterval(() => {
        const header = document.querySelector('.gm-style-iw-chr');
        if (header && !header.querySelector('.info-window-title')) {
          const imageElement = document.createElement('img');
          imageElement.src = selectedEvent.image; // Assuming event.image holds the URL for the image
          imageElement.alt = selectedEvent.title;

          const titleElement = document.createElement('h2');
          titleElement.className = 'info-window-title';
          titleElement.textContent = selectedEvent.title;

          header.insertBefore(titleElement, header.firstChild);
          header.insertBefore(imageElement, header.firstChild);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [selectedEvent]);

  const renderMarkers = useCallback(() => {
    if (!isLoaded || !window.google) return null;

    return EVENT_LIST.map(
      (event) =>
        event.locationCoords &&
        typeof event.locationCoords.lat === 'number' &&
        typeof event.locationCoords.lng === 'number' && (
          <MarkerF
            key={event.id}
            position={{
              lat: event.locationCoords.lat,
              lng: event.locationCoords.lng,
            }}
            // icon={{
            //   url: event.image,
            //   scaledSize: new window.google.maps.Size(50, 50),
            // }}
            onClick={() => handleMarkerClick(event.id)}
          >
            {activeMarker === event.id && (
              <InfoWindowF
                position={{
                  lat: event.locationCoords.lat,
                  lng: event.locationCoords.lng,
                }}
                onCloseClick={() => setActiveMarker(null)}
              >
                <div className="info-window-content mt-4 shadow-xl">
                  <span className="px-2 py-1 rounded-2xl bg-gray-200 text-gray-500 text-sm">
                    {event.dateRange}
                  </span>
                  <div className="flex justify-between items-center">
                    <Rating rating={event.rating} />
                    <button className="mt-2 px-4 py-2 bg-yellow-03 text-white font-bold rounded outline-none">
                      See More
                    </button>
                  </div>
                </div>
              </InfoWindowF>
            )}
          </MarkerF>
        )
    );
  }, [activeMarker, isLoaded, selectedEvent]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div className="flex justify-between bg-gray-100 px-2">
      <div className="overflow-y-auto" style={{ maxHeight: '100vh' }}>
        <EventSearchBar small currentSearch="events" />
        {EVENT_LIST.map((event) => (
          <div
            key={event.id}
            className="p-2 px-4"
            onClick={() => handleEventClick(event)}
          >
            <EventCardWrapper
              isActive={event.id === selectedEvent.id}
              small
              event={event}
            />
          </div>
        ))}
      </div>
      <div className="w-full h-screen pl-2">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
          >
            {renderMarkers()}
          </GoogleMap>
        ) : (
          <div className="flex justify-center items-center h-full">
            <ClipLoader color="#000" loading={true} size={150} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventListMap;
