import { categories } from './categories';

export const getMacroName = (category) => {
  const macro = categories.find((cat) => cat.icon === category);
  return macro ? macro.macro : '';
};

export const getMicroName = (category) => {
  const micro = categories.find((cat) =>
    cat.micro.find((m) => m.icon === category)
  );
  return micro ? micro.micro.find((m) => m.icon === category).name : '';
};
