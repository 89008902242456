import { useState } from 'react';

export const useImageCropper = () => {
  const [imagePreview, setImagePreview] = useState(null); // Image preview for the cropper
  const [croppedImage, setCroppedImage] = useState(null); // Final cropped image
  const [isCropping, setIsCropping] = useState(false); // Control the cropping modal

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Preview raw image before cropping
        setIsCropping(true); // Open cropping modal
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropSave = (croppedBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(croppedBlob);
    reader.onloadend = () => {
      setCroppedImage(reader.result); // Store the cropped image
    };
    setIsCropping(false); // Close cropping modal
  };

  const resetImage = () => {
    setImagePreview(null);
    setCroppedImage(null);
    setIsCropping(false);
  };

  return {
    imagePreview, // Raw image preview before cropping
    croppedImage, // Cropped image to use in the form
    isCropping, // Control to show/hide the cropper modal
    handleImageChange, // Function to handle image file change (called in the form input)
    handleCropSave, // Function to save the cropped image
    setIsCropping, // Manually close the cropping modal
    resetImage, // Reset the image preview and cropped image
  };
};
